import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { v4 as uuidv4 } from "uuid";

import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';

import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { MasterDataEnumType } from '../../Intervention/CommonDataParts/InterventionTypes';
import { defaultUnitsV2, unitLong } from '../../../../utils/unit-utils';
import { AnimalType, MasterDataOption, StageType } from '../../../../../../graphql/types';
import { BaselineDialogProps, FormType, ListEntry } from '../../common';
import { ShrimpBaseline } from '../../../../models/Baseline/ShrimpBaseline';
import { ShrimpIntervention, ShrimpOperations } from '../../../../models/Intervention/ShrimpIntervention';
import SGrowingFertilizerForm from '../../Baseline/Shrimp/SGrowingFertilizerForm';
import SGrowingMaterialForm from '../../Baseline/Shrimp/SGrowingMaterialForm';
import SGrowingChemicalForm from '../../Baseline/Shrimp/SGrowingChemicalForm';
import ShrimpResourcesDialog from './ShrimpResourcesDialog';
import ShrimpFertilizerForm from './ShrimpFertilizerForm';
import ShrimpMaterialForm from './ShrimpMaterialForm';
import ShrimpChemicalForm from './ShrimpChemicalForm';

interface ShrimpOperationsDialogProps
    extends Omit<BaselineDialogProps, 'baseline'> {
    masterDataEnums: MasterDataEnumType | null;
    baseline: ShrimpBaseline;
    animalType: AnimalType;
    stageIndex: number;
    stageType: StageType;
    singleIngredients?: any;
}

const ShrimpOperationsDialog: FC<ShrimpOperationsDialogProps> = ({
    formType = FormType.Add,
    stageIndex = 0,
    formVisible = false,
    handleCancel,
    animalType,
    stageType,
    masterDataEnums,
    baseline,
    singleIngredients
}) => {
    const intl = useIntl();
    const classes = processAndStageStylesV2() as any;
    const userProfile = UserProfilePrefs.getInstance();
    const userUOM = userProfile.getUserUnitPrefs();
    const formTitle = intl.formatMessage({ id: 'SUSTELL.PROCESS.DIALOG.STAGE.OPERATIONS.TITLE', });

    const fieldItemPrefix = `stages.${stageIndex}.stageData.operations`;

    const currResetValue = useRef<ShrimpOperations>();
    const fc = useFormContext<ShrimpIntervention>();

    const operationsData = baseline.stages[stageIndex].stageData.operations;

    const [isResourcesEdit, setIsResourcesEdit] = useState(false);
    const [isWaterResourcesEdit, setIsWaterResourcesEdit] = useState(false);
    const [isFertilizerEdit, setIsFertilizerEdit] = useState<boolean>(false);
    const [isMaterialsEdit, setIsMaterialsEdit] = useState<boolean>(false);
    const [isChemicalsEdit, setIsChemicalsEdit] = useState<boolean>(false);
    const [isIceAmountEdit, setIsIceAmountEdit] = useState(false);

    const feedsToFertilizer: string[] = [
        'Sugar beet molasses'
    ];

    const { control, trigger } = useFormContext<ShrimpBaseline>();

    const {
        fields: fertilizerTypes,
        append: addFertilizerType,
        remove: removeFertilizerType,
    } = useFieldArray({
        name: `${fieldItemPrefix}.fertilizerTypeAdditions`,
        control,
        keyName: 'keyId',
    });

    const addFertilizer = () => {
        addFertilizerType({
            type: '',
            amount: '',
        });
    };

    const removeFertilizer = (index: number) => {
        removeFertilizerType(index);
    };

    const {
        fields: materialTypes,
        append: addMaterialType,
        remove: removeMaterialType,
    } = useFieldArray({
        name: `${fieldItemPrefix}.materialTypeAdditions`,
        control,
        keyName: 'keyId',
    });

    const addMaterial = () => {
        addMaterialType({
            type: '',
            amount: '',
            transportMode: '',
            distanceTraveled: '',
            lifetimeOfMaterial: '',
        });
    };

    const removeMaterial = (index: number) => {
        removeMaterialType(index);
    };

    const {
        fields: chemicalTypes,
        append: addChemicalType,
        remove: removeChemicalType,
    } = useFieldArray({
        name: `${fieldItemPrefix}.chemicalTypeAdditions`,
        control,
        keyName: 'keyId',
    });

    const addChemical = () => {
        addChemicalType({
            type: '',
            amount: '',
        });
    };

    const removeChemical = (index: number) => {
        removeChemicalType(index);
    };

    const formatItem = (option: MasterDataOption) => {
        let formatedValue = {
            value: option.value,
            text: option.value
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
        };

        return formatedValue;
    }

    const getSalinityOptions = () => {
        let salinityOptions: ListEntry[] = [];

        masterDataEnums?.Salinity?.forEach((option) => {
            let newValue = formatItem(option);
            salinityOptions.push(newValue);
        });

        salinityOptions.sort((optionA, optionB) =>
            optionA.value.localeCompare(optionB.value)
        );

        return salinityOptions;
    };

    const getFertilizerOptions = () => {
        let fertilizerTypeOptions: ListEntry[] = [];

        masterDataEnums?.FertilizerType?.forEach((option) => {
            let newValue = formatItem(option);
            fertilizerTypeOptions.push(newValue);
        });

        if (singleIngredients) {
            const ingredient = singleIngredients.find((ingredient: any) =>
                feedsToFertilizer.includes(ingredient?.value)
            );
            if (ingredient)
                fertilizerTypeOptions.push(ingredient);
        }

        fertilizerTypeOptions.sort((optionA, optionB) =>
            optionA.value.localeCompare(optionB.value)
        );

        return fertilizerTypeOptions;
    };

    const getMaterialOptions = () => {
        const materialTypeOptions: ListEntry[] = [];

        let materialType: Maybe<MasterDataOption[]> | undefined = []
        switch (stageType) {
            case StageType.Hatching:
                materialType = masterDataEnums?.HatcheryStageMaterialType;
                break;
            default:
                materialType = masterDataEnums?.GrowingStageMaterialType;
                break;
        }
        materialType?.forEach((option) => {
            const newValue = formatItem(option);
            materialTypeOptions.push(newValue);
        });

        materialTypeOptions.sort((optionA, optionB) =>
            optionA.value.localeCompare(optionB.value)
        );

        return materialTypeOptions;
    };

    const getTransportOptions = () => {
        let transportModeOptions: ListEntry[] = [];

        masterDataEnums?.TransportMode?.forEach((option) => {
            let newValue = formatItem(option);
            transportModeOptions.push(newValue);
        });

        transportModeOptions.sort((optionA, optionB) =>
            optionA.value.localeCompare(optionB.value)
        );

        return transportModeOptions;
    };

    const getChemicalOptions = () => {
        let chemicalOptions: ListEntry[] = [];

        masterDataEnums?.ShrimpProductionChemicalType?.forEach((option) => {
            let newValue = formatItem(option);
            chemicalOptions.push(newValue);
        });

        chemicalOptions.sort((optionA, optionB) =>
            optionA.value.localeCompare(optionB.value)
        );

        return chemicalOptions;
    };

    useEffect(() => {
        if (formVisible) {
            currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix));
        }
    }, [formVisible]);

    const handleResetClick = () => {
        if (currResetValue.current) {
            const resetObject = { ...fc.getValues() } as ShrimpIntervention;

            if (resetObject.stages[stageIndex]?.stageData?.operations) {
                resetObject.stages[stageIndex].stageData.operations = currResetValue.current;
                fc.reset(resetObject, {
                    errors: true,
                });
            }
        }
        if (handleCancel) {
            handleCancel('reset');
        }
    };

    const cancelFertilizersEdit = () => {
        (operationsData?.fertilizerTypes || [])
            ?.filter((item) => !!item.type)
            ?.forEach((_, index) => {
                fc.setValue(
                    `${fieldItemPrefix}.fertilizerTypes[${index}].type`,
                    ""
                );
            });
        for (let i = fertilizerTypes.length - 1; i >= 0; i--) {
            removeFertilizerType(i);
        }
        setIsFertilizerEdit(false);
    };

    const cancelMaterialsEdit = () => {
        (operationsData?.materialTypes || [])
            ?.filter((item) => !!item.type)
            ?.forEach((_, index) => {
                fc.setValue(
                    `${fieldItemPrefix}.materialTypes[${index}].type`,
                    ""
                );
            });
        for (let i = materialTypes.length - 1; i >= 0; i--) {
            removeMaterialType(i);
        }
        setIsMaterialsEdit(false);
    };

    const cancelChemicalsEdit = () => {
        (operationsData?.chemicalTypes || [])
            ?.filter((item) => !!item.type)
            ?.forEach((_, index) => {
                fc.setValue(
                    `${fieldItemPrefix}.chemicalTypes[${index}].type`,
                    ""
                );
            });
        for (let i = chemicalTypes.length - 1; i >= 0; i--) {
            removeChemicalType(i);
        }
        setIsChemicalsEdit(false);
    };

    return (
        <DialogContainer
            formVisible={formVisible}
            handleClose={handleResetClick}
            formTitle={formTitle}
            introText={''}
            iconCode="general/settings-01"
            width="1024px"
        >
            <DsmGrid className={classes.dsmGridOneColumn} style={{ gap: 0 }}>

                <h4>{intl.formatMessage({ id: 'GENERAL.RESOURCES' })}</h4>
                <DsmGrid className={classes.dsmGridOneColumnWithBorder} style={{ padding: 'var(--dsm-spacing-px-4)' }}>
                    <ShrimpResourcesDialog
                        formType={formType}
                        editEnabled={isResourcesEdit}
                        stageIndex={stageIndex}
                        inputType={`stages.${stageIndex}.stageData.operations.resourceUse`}
                        animalType={animalType}
                        baseline={baseline}
                    />

                    <DsmGrid className={classes.dsmGridTwoColumn} style={{ paddingTop: 'var(--dsm-spacing-px-4)' }}>
                        {!isResourcesEdit && !fc.getValues(`${fieldItemPrefix}.resourceUse.waterUse`) ? (
                            <ReactHookDsmInput
                                name={`${fieldItemPrefix}.resourceUse.originalWaterUse`}
                                label={intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.FRESH_WATER',
                                })}
                                type="number"
                                tooltip={intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.FRESH_WATER.TOOLTIP',
                                })}
                                adornment={
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                                    userUOM?.unitWaterUse
                                        ? unitLong(userUOM.unitWaterUse)
                                        : defaultUnitsV2.unitWaterUse
                                }
                                disabled
                                defaultValue={operationsData?.resourceUse?.waterUse}
                            />) : (
                            <RowTextFieldWithMetrics
                                name={`${fieldItemPrefix}.resourceUse.waterUse`}
                                label={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.FRESH_WATER', })}
                                type="number"
                                tooltip=' '
                                metricUnit=''
                                baseline={operationsData?.resourceUse?.waterUse}
                                disabled={formType === FormType.View || !isResourcesEdit}
                                displayTypeSelect={isResourcesEdit}
                            />)
                        }

                        <ReactHookDsmSelect
                            label={intl.formatMessage({
                                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.SALINITY',
                            })}
                            placeholder={intl.formatMessage({
                                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.SALINITY.PLACEHOLDER',
                            })}
                            name={`stages.${stageIndex}.stageData.operations.resourceUse.originalWaterSalinity`}
                            options={getSalinityOptions()}
                            disabled={(!isResourcesEdit || formType === FormType.View)}
                            required
                            tooltip={intl.formatMessage({
                                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.SALINITY.TOOLTIP',
                            })}
                            adornment=" "
                            defaultValue={operationsData?.resourceUse?.watersalinity as unknown as string}
                        />
                    </DsmGrid>

                    <DsmButton
                        variant="text"
                        style={{
                            textDecoration: 'underline',
                            color: '#0070BA',
                            display: (!isResourcesEdit && formType !== 'view') ? 'flex' : 'none',
                            placeSelf: 'start',
                            textTransform: 'none',
                            marginTop: 'var(--dsm-spacing-px-2)'
                        }}
                        onClick={() => setIsResourcesEdit(true)}
                        disabled={(formType === 'view' || isResourcesEdit === true)}
                    >
                        <DsmIcon
                            slot="before"
                            name="editor/pencil-01"
                            style={{ paddingRight: '5px', height: '24px', width: '24px' }}
                        />
                        {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
                    </DsmButton>

                    {isResourcesEdit && (
                        <>
                            <DsmButton
                                variant="text"
                                destructive
                                onClick={() => setIsResourcesEdit(false)}
                                disabled={formType === 'view'}
                                style={{ textDecoration: 'underline', color: 'red', paddingTop: 'var(--dsm-spacing-px-2)' }}
                            >
                                <DsmIcon
                                    slot="before"
                                    name="general/minus-circle"
                                />
                                {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                            </DsmButton>
                        </>
                    )}
                </DsmGrid>

                <DsmGrid className={classes.dsmGridOneColumnWithBorder} style={{ padding: 'var(--dsm-spacing-px-4)' }}>
                    <DsmGrid className={classes.dsmGridTwoColumnNoRowGap}>
                        {!isWaterResourcesEdit && !fc.getValues(`${fieldItemPrefix}.resourceUse.waterEnteringPondIn`) ? (
                            <ReactHookDsmInput
                                name={`${fieldItemPrefix}.resourceUse.originalWaterEnteringPondIn`}
                                label={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_IN_POND', })}
                                tooltip={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_IN_POND.TOOLTIP', })}
                                type="number"
                                adornment={
                                    userUOM?.unitWaterUse
                                        ? unitLong(userUOM.unitWaterUse)
                                        : defaultUnitsV2.unitWaterUse
                                }
                                disabled
                                defaultValue={operationsData?.resourceUse?.waterEnteringPondIn}
                            />
                        ) : (
                            <RowTextFieldWithMetrics
                                name={`${fieldItemPrefix}.resourceUse.waterEnteringPondIn`}
                                label={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_IN_POND', })}
                                type="number"
                                tooltip={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_IN_POND.TOOLTIP', })}
                                metricUnit=''
                                baseline={operationsData?.resourceUse?.waterEnteringPondIn}
                                disabled={formType === FormType.View || !isWaterResourcesEdit}
                                displayTypeSelect={isWaterResourcesEdit}
                            />
                        )}

                        {!isWaterResourcesEdit && !fc.getValues(`${fieldItemPrefix}.resourceUse.nitrogenConcentrationIn`) ? (
                            <ReactHookDsmInput
                                name={`${fieldItemPrefix}.resourceUse.originalNitrogenConcentrationIn`}
                                label={intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION',
                                })}
                                tooltip={intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION.TOOLTIP',
                                })}
                                type="number"
                                adornment={intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION.PLACEHOLDER',
                                })}
                                disabled
                                defaultValue={operationsData?.resourceUse?.nitrogenConcentrationIn as string}
                            />) : (
                            <RowTextFieldWithMetrics
                                name={`${fieldItemPrefix}.resourceUse.nitrogenConcentrationIn`}
                                label={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION', })}
                                type="number"
                                tooltip={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION.TOOLTIP', })}
                                metricUnit=''
                                baseline={operationsData?.resourceUse?.nitrogenConcentrationIn}
                                disabled={formType === FormType.View || !isWaterResourcesEdit}
                                displayTypeSelect={isWaterResourcesEdit}
                            />
                        )}

                        {!isWaterResourcesEdit && !fc.getValues(`${fieldItemPrefix}.resourceUse.waterEnteringPondOut`) ? (
                            <ReactHookDsmInput
                                name={`${fieldItemPrefix}.resourceUse.originalWaterEnteringPondOut`}
                                label={intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_OUT_POND',
                                })}
                                tooltip={intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_OUT_POND.TOOLTIP',
                                })}
                                type="number"
                                adornment={
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                                    userUOM?.unitWaterUse
                                        ? unitLong(userUOM.unitWaterUse)
                                        : defaultUnitsV2.unitWaterUse
                                }
                                disabled
                                defaultValue={operationsData?.resourceUse?.waterEnteringPondOut}
                            />) : (
                            <RowTextFieldWithMetrics
                                name={`${fieldItemPrefix}.resourceUse.waterEnteringPondOut`}
                                label={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_OUT_POND', })}
                                type="number"
                                tooltip={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.WATER_ENTERING_OUT_POND.TOOLTIP', })}
                                metricUnit=''
                                baseline={operationsData?.resourceUse?.waterEnteringPondOut}
                                disabled={formType === FormType.View || !isWaterResourcesEdit}
                                displayTypeSelect={isWaterResourcesEdit}
                            />
                        )}

                        {!isWaterResourcesEdit && !fc.getValues(`${fieldItemPrefix}.resourceUse.nitrogenConcentrationOut`) ? (
                            <ReactHookDsmInput
                                name={`${fieldItemPrefix}.resourceUse.originalNitrogenConcentrationOut`}
                                label={intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION',
                                })}
                                tooltip={intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION.TOOLTIP',
                                })}
                                type="number"
                                adornment={intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION.PLACEHOLDER',
                                })}
                                disabled
                                defaultValue={operationsData?.resourceUse?.nitrogenConcentrationOut as string}
                            />) : (
                            <RowTextFieldWithMetrics
                                name={`${fieldItemPrefix}.resourceUse.nitrogenConcentrationOut`}
                                label={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION', })}
                                type="number"
                                tooltip={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.NITROGEN_CONCENTRATION.TOOLTIP', })}
                                metricUnit=''
                                baseline={operationsData?.resourceUse?.nitrogenConcentrationOut}
                                disabled={formType === FormType.View || !isWaterResourcesEdit}
                                displayTypeSelect={isWaterResourcesEdit}
                            />
                        )}

                        {!isWaterResourcesEdit ? (
                            <DsmButton
                                variant="text"
                                style={{
                                    textDecoration: 'underline',
                                    color: '#0070BA',
                                    display: (!isWaterResourcesEdit && formType !== 'view') ? 'flex' : 'none',
                                    placeSelf: 'start',
                                    textTransform: 'none',
                                }}
                                onClick={() => setIsWaterResourcesEdit(true)}
                                disabled={formType === 'view'}
                            >
                                <DsmIcon
                                    slot="before"
                                    name="editor/pencil-01"
                                    style={{ paddingRight: '5px', height: '24px', width: '24px' }}
                                />
                                {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
                            </DsmButton>
                        ) : (
                            <>
                                <DsmButton
                                    variant="text"
                                    destructive
                                    onClick={() => setIsWaterResourcesEdit(false)}
                                    disabled={formType === 'view'}
                                    style={{ textDecoration: 'underline', color: 'red' }}
                                >
                                    <DsmIcon
                                        slot="before"
                                        name="general/minus-circle"
                                    />
                                    {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                                </DsmButton>
                            </>
                        )}
                    </DsmGrid>
                </DsmGrid>

                <br />

                <h4>
                    {intl.formatMessage({
                        id: 'GENERAL.FERTILIZER',
                    })}
                </h4>

                <DsmGrid className={classes.dsmGridOneColumnWithBorder} style={{ padding: 'var(--dsm-spacing-px-4)' }}>
                    {operationsData &&
                        operationsData?.fertilizerTypes.map((item, index) => (
                            <ShrimpFertilizerForm
                                key={uuidv4()}
                                stageIndex={stageIndex}
                                itemIndex={index}
                                formType={formType}
                                baseline={baseline}
                                editEnabled={isFertilizerEdit}
                                removeHandler={removeFertilizer}
                            />
                        ))
                    }

                    {fertilizerTypes.length > 0 ? (
                        fertilizerTypes && fertilizerTypes?.map((item, index) => (
                            <div style={{ paddingTop: isFertilizerEdit ? 'var(--dsm-spacing-px-2)' : '' }}>
                                <SGrowingFertilizerForm
                                    key={item.keyId}
                                    stageIndex={stageIndex}
                                    itemIndex={index}
                                    formType={isFertilizerEdit ? FormType.Add : FormType.View}
                                    isIntervention
                                    removeHandler={removeFertilizer}
                                    options={getFertilizerOptions()}
                                />
                            </div>
                        ))) : (<></>)
                    }

                    <DsmButton
                        variant="text"
                        style={{
                            textDecoration: 'underline',
                            color: '#0070BA',
                            display: (!isFertilizerEdit && formType !== 'view') ? 'flex' : 'none',
                            placeSelf: 'start',
                            textTransform: 'none',
                        }}
                        onClick={() => setIsFertilizerEdit(true)}
                        disabled={(formType === 'view' || isFertilizerEdit === true)}
                    >
                        <DsmIcon
                            slot="before"
                            name="editor/pencil-01"
                            style={{ paddingRight: '5px', width: '24px' }}
                        />
                        {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
                    </DsmButton>

                    {isFertilizerEdit && (
                        <>
                            <DsmButton
                                variant="text"
                                onClick={addFertilizer}
                                disabled={formType === FormType.View}
                                style={{ textDecoration: 'underline', color: '#0070BA', }}
                            >
                                <DsmIcon slot="before" name="general/plus-circle" />
                                {intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ADD_FERTILIZER_TYPE',
                                })}
                            </DsmButton>
                            <DsmButton
                                variant="text"
                                destructive
                                onClick={() => cancelFertilizersEdit()}
                                disabled={formType === 'view'}
                                style={{ textDecoration: 'underline', color: 'red' }}
                            >
                                <DsmIcon
                                    slot="before"
                                    name="general/minus-circle"
                                />
                                {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                            </DsmButton>
                        </>
                    )}
                </DsmGrid>

                <br />

                <h4>
                    {intl.formatMessage({
                        id: 'BASELINE.FORM.OPERATIONS.SHRIMP.MATERIALS',
                    })}
                </h4>

                <DsmGrid className={classes.dsmGridOneColumnWithBorder} style={{ padding: 'var(--dsm-spacing-px-4)' }}>
                    {operationsData &&
                        operationsData.materialTypes.map((item, index) => (
                            <DsmGrid className={(isMaterialsEdit || materialTypes.length > 0) ? classes.dsmGridOneColumnWithBorder : classes.dsmGridOneColumn}
                                style={{ padding: (isMaterialsEdit || materialTypes.length > 0) ? 'var(--dsm-spacing-px-4)' : '' }}>
                                <ShrimpMaterialForm
                                    key={uuidv4()}
                                    stageIndex={stageIndex}
                                    itemIndex={index}
                                    formType={formType}
                                    editEnabled={isMaterialsEdit}
                                    removeHandler={removeMaterial}
                                    transportOptions={getTransportOptions()}
                                    baseline={baseline}
                                />
                            </DsmGrid>
                        ))}

                    {materialTypes ? (
                        materialTypes.length > 0 && materialTypes?.map((item, index) => (
                            <DsmGrid className={classes.dsmGridOneColumnWithBorder}>
                                <SGrowingMaterialForm
                                    key={item.keyId}
                                    stageIndex={stageIndex}
                                    itemIndex={index}
                                    formType={isMaterialsEdit ? FormType.Add : FormType.View}
                                    isIntervention
                                    removeHandler={removeMaterial}
                                    materialOptions={getMaterialOptions()}
                                    transportOptions={getTransportOptions()}
                                />
                            </DsmGrid>
                        ))
                    ) : (<></>)}

                    <DsmButton
                        variant="text"
                        style={{
                            textDecoration: 'underline',
                            color: '#0070BA',
                            display: (!isMaterialsEdit && formType !== 'view') ? 'flex' : 'none',
                            placeSelf: 'start',
                            textTransform: 'none',
                        }}
                        onClick={() => setIsMaterialsEdit(true)}
                        disabled={(formType === 'view' || isMaterialsEdit === true)}
                    >
                        <DsmIcon
                            slot="before"
                            name="editor/pencil-01"
                            style={{ paddingRight: '5px', height: '24px', width: '24px' }}
                        />
                        {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
                    </DsmButton>

                    {isMaterialsEdit && (
                        <>
                            <DsmButton
                                variant="text"
                                onClick={addMaterial}
                                disabled={formType === FormType.View}
                                style={{ textDecoration: 'underline', color: '#0070BA', }}
                            >
                                <DsmIcon slot="before" name="general/plus-circle" />
                                {intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ADD_MATERIALS_TYPE',
                                })}
                            </DsmButton>

                            <DsmButton
                                variant="text"
                                destructive
                                onClick={() => cancelMaterialsEdit()}
                                disabled={formType === 'view'}
                                style={{ textDecoration: 'underline', color: 'red' }}
                            >
                                <DsmIcon
                                    slot="before"
                                    name="general/minus-circle"
                                />
                                {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                            </DsmButton>
                        </>
                    )}
                </DsmGrid>

                <br />

                <h4>
                    {intl.formatMessage({
                        id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICALS',
                    })}
                </h4>

                <DsmGrid className={classes.dsmGridOneColumnWithBorder} style={{ padding: 'var(--dsm-spacing-px-4)' }}>
                    {operationsData &&
                        operationsData.chemicalTypes?.map((item, index) => (
                            <ShrimpChemicalForm
                                key={uuidv4()}
                                stageIndex={stageIndex}
                                itemIndex={index}
                                formType={formType}
                                editEnabled={isChemicalsEdit}
                                removeHandler={removeChemical}
                                options={getChemicalOptions()}
                                baseline={baseline}
                            />
                        ))}

                    {chemicalTypes ? (
                        chemicalTypes.length > 0 && chemicalTypes.map((item, index) => (
                            <div style={{ paddingTop: isChemicalsEdit ? 'var(--dsm-spacing-px-2)' : '' }}>
                                <SGrowingChemicalForm
                                    key={item.keyId}
                                    stageIndex={stageIndex}
                                    itemIndex={index}
                                    formType={isChemicalsEdit ? FormType.Add : FormType.View}
                                    isIntervention
                                    removeHandler={removeChemical}
                                    options={getChemicalOptions()}
                                />
                            </div>
                        ))
                    ) : (<></>)}

                    <DsmButton
                        variant="text"
                        style={{
                            textDecoration: 'underline',
                            color: '#0070BA',
                            display: (!isChemicalsEdit && formType !== 'view') ? 'flex' : 'none',
                            placeSelf: 'start',
                            textTransform: 'none',
                        }}
                        onClick={() => setIsChemicalsEdit(true)}
                        disabled={(formType === 'view' || isChemicalsEdit === true)}
                    >
                        <DsmIcon
                            slot="before"
                            name="editor/pencil-01"
                            style={{ paddingRight: '5px', height: '24px', width: '24px' }}
                        />
                        {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
                    </DsmButton>

                    {isChemicalsEdit && (
                        <>
                            <DsmButton
                                variant="text"
                                onClick={addChemical}
                                disabled={formType === FormType.View}
                                style={{ textDecoration: 'underline', color: '#0070BA', }}
                            >
                                <DsmIcon slot="before" name="general/plus-circle" />
                                {intl.formatMessage({
                                    id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ADD_CHEMICAL_TYPE',
                                })}
                            </DsmButton>

                            <DsmButton
                                variant="text"
                                destructive
                                onClick={() => cancelChemicalsEdit()}
                                disabled={formType === 'view'}
                                style={{ textDecoration: 'underline', color: 'red' }}
                            >
                                <DsmIcon
                                    slot="before"
                                    name="general/minus-circle"
                                />
                                {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                            </DsmButton>
                        </>
                    )}
                </DsmGrid>

                <br />

                {!isIceAmountEdit && !fc.getValues(`${fieldItemPrefix}.iceAmount`) ? (
                    <>
                        <ReactHookDsmInput
                            name={`${fieldItemPrefix}.originalIceAmount`}
                            label={`${intl.formatMessage({
                                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ICE_AMOUNT',
                            })}`}
                            tooltip={intl.formatMessage({
                                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ICE_AMOUNT.TOOLTIP',
                            })}
                            adornment={intl.formatMessage({
                                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ICE_AMOUNT.PLACEHOLDER',
                            })}
                            type="number"
                            disabled={!isIceAmountEdit}
                            defaultValue={operationsData?.iceAmount}
                        />

                        <DsmButton
                            variant="text"
                            style={{
                                textDecoration: 'underline',
                                color: '#0070BA',
                                display: (!isIceAmountEdit && formType !== 'view') ? 'flex' : 'none',
                                placeSelf: 'start',
                                textTransform: 'none',
                            }}
                            onClick={() => setIsIceAmountEdit(true)}
                            disabled={formType === 'view'}
                        >
                            <DsmIcon
                                slot="before"
                                name="editor/pencil-01"
                                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
                            />
                            {intl.formatMessage({ id: 'GENERAL.EDIT_INPUT' })}
                        </DsmButton>
                    </>
                ) : (
                    <>
                        <RowTextFieldWithMetrics
                            name={`${fieldItemPrefix}.iceAmount`}
                            label={intl.formatMessage({ id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ICE_AMOUNT' })}
                            type="number"
                            tooltip={intl.formatMessage({
                                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.ICE_AMOUNT.TOOLTIP',
                            })}
                            metricUnit=''
                            baseline={operationsData?.iceAmount}
                            disabled={formType === FormType.View || !isIceAmountEdit}
                            displayTypeSelect={isIceAmountEdit}
                        />

                        <br />
                        <DsmButton
                            variant="text"
                            destructive
                            onClick={() => setIsIceAmountEdit(false)}
                            disabled={formType === 'view'}
                            style={{ textDecoration: 'underline', color: 'red' }}
                        >
                            <DsmIcon
                                slot="before"
                                name="general/minus-circle"
                            />
                            {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                        </DsmButton>
                    </>
                )}

                <DsmButtonControlGroup
                    cancelHandler={handleResetClick}
                    saveHandler={() => {
                        fc.trigger(fieldItemPrefix);
                        handleCancel('confirm');
                    }}
                    saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
                />
            </DsmGrid>
        </DialogContainer >
    );
};

export default ShrimpOperationsDialog;
