import { CompoundFeedDatabase, ResourceUseType } from '../../../graphql/types';
import { CFDatabaseFoundation } from '../models/CompoundFeed';

export interface LookupValues {
  units: {
    [key: string]: {
      [key: string]: {
        label: string;
      };
    };
  };
}

const lookupValues = {
  farmingMethod: { CONVENTIONAL: 'Conventional' },
  animalType: {
    LAYING_HENS: 'Laying hens',
    DAIRY: 'Dairy',
    BROILER: 'Broiler',
    PIG_FATTENING: 'Pig fattening',
    PIG: 'Pig',
    SALMON: 'Salmon',
    SALMON_V2: 'Salmon',
    SEA_BASS: 'Sea bass',
    SEA_BREAM: 'Sea bream',
    BROILER_V2: 'Broiler',
    LAYING_HENS_V2: 'Laying hens',
    TURKEY: 'Turkey',
  },
  productionSystems: {
    LAYING_HENS: ['Aviary', 'Caged', 'Enhanced cage'],
    DAIRY: [
      'Bulls',
      'Calves 1-2 year',
      'Calves <1 year',
      'Dairy cows',
      'Heifers',
    ],
    BROILER: ['Broilers'],
    PIG_FATTENING: ['Pig'],
    SALMON: ['Salmon'],
    LITOPENAEUS_VANNAMEI: ['Grow-out'],
    PENAEUS_MONODON: ['Grow-out'],
  },
  housingTypes: {
    Dairy: { housed: 'Housed', 'free ranging': 'Free ranging' },
  },
  interventionValueMetrics: {
    relative: 'Relative (%)',
    absolute: 'Absolute (value)',
    set: 'Set (value)',
  },
  interventionCategories: {
    Facilities: 'Facilities',
    Genetics: 'Genetics',
    Health: 'Health',
    'Management practices': 'Management practices',
    Manure: 'Manure',
    Nutritional: 'Nutritional',
  },
  compartments: ['Air', 'Land', 'Human', 'Water'],
  processingOutputTypeMappings: {
    'fresh meat and edible offal': 'FRESH_MEAT_AND_EDIBLE_OFFAL',
    'food grade bones': 'FOOD_GRADE_BONES',
    'food grade fat': 'FOOD_GRADE_FAT',
    'cat 1 and 2 material and waste': 'CAT1_AND2_MATERIAL_AND_WASTE',
    'cat 3 slaughter by products': 'CAT3_SLAUGHTER_BY_PRODUCTS',
    'cat 3 hides and skins': 'CAT3_HIDES_AND_SKINS',
  },
  processingOutputDefaultPrices: {
    PIG: {
      freshMeatAndEdibleOffal: 7,
      foodGradeBones: 0.02,
      foodGradeFat: 0,
      cat1And2MaterialAndWaste: 0.03,
      cat3SlaughterByProducts: 0.03,
      cat3HidesAndSkins: 0,
    },
    BEEF: {
      freshMeatAndEdibleOffal: 3,
      foodGradeBones: 0.19,
      foodGradeFat: 0.40,
      cat1And2MaterialAndWaste: 0,
      cat3SlaughterByProducts: 0.18,
      cat3HidesAndSkins: 0.80,
    },
  },
  impactCategoriesMapping: {
    primary: {
      'Climate change': ['Air'],
      'Global warming': ['Air'],
      'Land use': ['Land'],
      'Respiratory inorganics': ['Human'],
      'Human carcinogenic toxicity': ['Human'],
      'Water scarcity': ['Water'],
      'Water consumption': ['Water'],
    },
    all: {
      'Climate change': ['Air'],
      'Land use': ['Land'],
      'Respiratory inorganics': ['Human'],
      'Water scarcity': ['Water'],
      'Acidification terrestrial and freshwater': ['Land', 'Water'],
      'Cancer human health effects': ['Human'],
      'Climate change - biogenic': ['Air'],
      'Climate change - fossil': ['Air'],
      'Climate change - land use and transform.': ['Land', 'Air'],
      'Eutrophication freshwater': ['Water'],
      'Eutrophication marine': ['Water'],
      'Eutrophication terrestrial': ['Land'],
      'Ionising radiation, HH': ['Human'],
      'Non-cancer human health effects': ['Human'],
      'Ozone depletion': ['Human'],
      'Photochemical ozone formation, HH': ['Human'],
      'Resource use, energy carriers': ['Land'],
      'Resource use, mineral and metals': ['Land'],
      // TODO: check these mappings, there categories are added afternew CF/PIG footprints
      // TODO: There are duplocates find a way to unify those categories
      Acidification: ['Land', 'Water'],
      'Climate change - Peat oxidation': ['Air'],
      'Climate change - Biogenic': ['Air'],
      'Climate change - Fossil': ['Air'],
      'Climate change - Land use and LU change': ['Land', 'Air'],
      'Ecotoxicity freshwater': ['Water'],
      'Ecotoxicity, freshwater - part 1': ['Water'],
      'Ecotoxicity, freshwater - part 2': ['Water'],
      'Ecotoxicity, freshwater': ['Water'],
      'Ecotoxicity, freshwater - inorganics': ['Water'],
      'Ecotoxicity, freshwater - organics - p.1': ['Water'],
      'Ecotoxicity, freshwater - organics - p.2': ['Water'],
      'Ecotoxicity, freshwater - organics': ['Water'],
      'Ecotoxicity, freshwater - metals': ['Water'],
      'Particulate matter': ['Human'],
      'Eutrophication, marine': ['Water'],
      'Eutrophication, freshwater': ['Water'],
      'Eutrophication, terrestrial': ['Land'],
      'Human toxicity, cancer': ['Human'],
      'Human toxicity, cancer - inorganics': ['Human'],
      'Human toxicity, cancer - organics': ['Human'],
      'Human toxicity, cancer - metals': ['Human'],
      'Human toxicity, non-cancer': ['Human'],
      'Human toxicity, non-cancer - inorganics': ['Human'],
      'Human toxicity, non-cancer - organics': ['Human'],
      'Human toxicity, non-cancer - metals': ['Human'],
      'Ionising radiation': ['Human'],
      'Photochemical ozone formation': ['Human'],
      'Resource use, fossils': ['Land'],
      'Resource use, minerals and metals': ['Land'],
      'Water use': ['Water'],
    },
    primaryImpactCategoryToAnimalMappings: {
      'Climate change': [
        'Laying hens',
        'Dairy',
        'Broiler',
        'Pig fattening',
        'Salmon',
      ],
      'Land use': [
        'Laying hens',
        'Dairy',
        'Broiler',
        'Pig fattening',
        'Salmon',
      ],
      'Respiratory inorganics': [
        'Laying hens',
        'Dairy',
        'Broiler',
        'Pig fattening',
        'Salmon',
      ],
      'Water scarcity': [
        'Laying hens',
        'Dairy',
        'Broiler',
        'Pig fattening',
        'Salmon',
      ],
    },
  },
  analysisGroups: [
    'Purchased animals',
    'Ration',
    'Farm',
    'Resources',
    'Transport',
    'Ingredients',
    'Processing',
    'Unknown',
  ],
  analysisGroupsMapping: {
    'Broilers to slaughter total live weight': {
      analysisGroup: 'Purchased animals',
      label: 'Broilers-to-slaughter',
    },
    'Diesel, from crude oil, consumption mix, at refinery, 200 ppm sulphur EU-15 S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Diesel, crude oil' },
    'Drinking water, water purification treatment, production mix, at plant, from groundwater RER S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Drinking water' },
    'Electricity from hydroelectric power plant, AC, production mix, at power plant, < 1kV RER S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity, hydroelectric' },
    'Electricity from wind power, AC, production mix, at wind turbine, < 1kV RER S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity, wind' },
    'Electricity medium voltage, AC, at maekt/SV APOS - S - copied from Ecoinvent':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity medium voltage, AC, at maekt/HN APOS - S - copied from Ecoinvent':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV BE S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV DE S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV DK S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV ES S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV FI S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV FR S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV GB S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV HU S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV IE S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV IT S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV NL S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV PL S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV/AU Economic': {
      analysisGroup: 'Resources',
      label: 'Electricity mix',
    },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV/BR Economic': {
      analysisGroup: 'Resources',
      label: 'Electricity mix',
    },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV/CA Economic': {
      analysisGroup: 'Resources',
      label: 'Electricity mix',
    },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV/CN Economic': {
      analysisGroup: 'Resources',
      label: 'Electricity mix',
    },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV/US Economic': {
      analysisGroup: 'Resources',
      label: 'Electricity mix',
    },
    'Electricity mix, AC, consumption mix, at consumer, < 1kV/VN Economic': {
      analysisGroup: 'Resources',
      label: 'Electricity mix',
    },
    'Electricity, anthracite coal, at power plant/RNA System - Copied from USLCI':
      { analysisGroup: 'Resources', label: 'Electricity, anthracite coal' },
    'Electricity, bituminous coal, at power plant/US System - Copied from USLCI':
      { analysisGroup: 'Resources', label: 'Electricity, bituminous coal' },
    'Electricity, diesel, at power plant/US U System - Copied from USLCI': {
      analysisGroup: 'Resources',
      label: 'Electricity, diesel',
    },
    'Electricity, lignite coal, at power plant/US System - Copied from USLCI': {
      analysisGroup: 'Resources',
      label: 'Electricity, lignite coal',
    },
    'Electricity, medium voltage {HN}| market for electricity, medium voltage | APOS, S':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity, medium voltage {SV}| market for electricity, medium voltage | APOS, S':
      { analysisGroup: 'Resources', label: 'Electricity mix' },
    'Electricity, natural gas, at power plant/US System - Copied from USLCI': {
      analysisGroup: 'Resources',
      label: 'Electricity, natural gas',
    },
    'Electricity, nuclear, at power plant/US System - Copied from USLCI': {
      analysisGroup: 'Resources',
      label: 'Electricity, nuclear',
    },
    'Electricity, residual fuel oil, at power plant/US System - Copied from USLCI':
      { analysisGroup: 'Resources', label: 'Electricity, residual fuel oil' },
    'Energy, from diesel burned in machinery/RER Economic': {
      analysisGroup: 'Resources',
      label: 'Energy, diesel',
    },
    'Heat, district or industrial, other than natural gas {RoW}| heat production, heavy fuel oil, at industrial furnace 1MW | APOS, S':
      { analysisGroup: 'Resources', label: 'Heat, fuel oil' },
    'Housing system - _housingname_': {
      analysisGroup: 'Farm',
      label: 'Housing',
    },
    'Manure boundary expansion for _housingname_': {
      analysisGroup: 'Farm',
      label: 'Manure boundary expansion',
    },
    'Laying hen <17 weeks, at farm/CA Economic': {
      analysisGroup: 'Purchased animals',
      label: 'Laying hen <17 weeks',
    },
    'Laying hen <17 weeks, at farm/NL Economic': {
      analysisGroup: 'Purchased animals',
      label: 'Laying hen <17 weeks',
    },
    'One-day-chicken, at farm/NL Economic': {
      analysisGroup: 'Purchased animals',
      label: 'One-day-chicken',
    },
    'Piglet, at farm/NL Economic': {
      analysisGroup: 'Purchased animals',
      label: 'Piglet',
    },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ FI S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, BE S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, DE S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, DK S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, ES S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, EU-27 S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, FR S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, GB S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, IE S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, IT S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, NL S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Process steam from natural gas, heat plant, consumption mix, at plant, MJ, PL S System - Copied from ELCD':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Propane, burned in building machine {GLO}| market for | APOS, S': {
      analysisGroup: 'Resources',
      label: 'Energy, propane',
    },
    'Ration for _housingname_': { analysisGroup: 'Ration', label: 'Ration' },
    'Saw dust, wet, measured as dry mass {GLO}| market for | APOS, S - Copied from Ecoinvent':
      { analysisGroup: 'Farm', label: 'Saw dust' },
    'Transport, truck >20t, EURO5, 100%LF, default/GLO Economic': {
      analysisGroup: 'Resources',
      label: 'Transport, truck',
    },
    'Transport, truck >20t, EURO4, 80%LF, default/GLO Economic': {
      analysisGroup: 'Resources',
      label: 'Transport, truck',
    },
    'Transport, truck >20t, EUR04, 80%LF. default/GLO Economic': {
      analysisGroup: 'Resources',
      label: 'Transport, truck',
    },
    'Transport, barge ship, bulk, 5500t, 80%LF, default/GLO Economic': {
      analysisGroup: 'Resources',
      label: 'Transport, ship',
    },
    'Tractor, production, at plant/RER Economic': {
      analysisGroup: 'Resources',
      label: 'Tractor',
    },
    'Wheat straw, at farm/AU Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/BE Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/BR Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/CA Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/CN Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/DE Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/DK Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/ES Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/FR Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/GB Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/IE Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/IN Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/IT Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/NL Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/PL Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/US Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    'Wheat straw, at farm/MX Economic': {
      analysisGroup: 'Farm',
      label: 'Wheat straw',
    },
    Feed: { analysisGroup: 'Ration', label: 'Feed' },
    'Feed - Hen': { analysisGroup: 'Ration', label: 'Feed - Hen' },
    'Feed - Rooster': { analysisGroup: 'Ration', label: 'Feed - Rooster' },
    'Animal Inputs - Breeding hen': {
      analysisGroup: 'Purchased animals',
      label: 'Animal Inputs - Breeding hen',
    },
    'Animal Inputs - Breeding rooster': {
      analysisGroup: 'Purchased animals',
      label: 'Animal Inputs - Breeding rooster',
    },
    'On-site emissions': { analysisGroup: 'Farm', label: 'On site emissions' },
    Smolt: { analysisGroup: 'Purchased animals', label: 'Smolt' },
    'Lice treatment': { analysisGroup: 'Farm', label: 'Lice treatment' },
    Transport: { analysisGroup: 'Resources', label: 'Transport' },
    'Materials for farm construction': {
      analysisGroup: 'Farm',
      label: 'Construction materials',
    },
    // Utilities: { analysisGroup: 'Resources', label: 'Utilities' },
    // 'Water use': { analysisGroup: 'Resources', label: 'Utilities' },

    // For utilities show resources in the category components view
    Utilities: { analysisGroup: 'Resources', label: 'Resources' },
    'Water use': { analysisGroup: 'Resources', label: 'Resources' },
    // TODO: check on this, added new mappings for CF V2 footprints
    'Electricity, low voltage {NL}| market for | Cut-off, S - Copied from ecoinvent':
      { analysisGroup: 'Resources', label: 'Process steam from natural gas' },
    'Animal Inputs': {
      analysisGroup: 'Purchased animals',
      label: 'Animal inputs',
    },
    Bedding: { analysisGroup: 'Farm', label: 'Bedding' },
    Materials: { analysisGroup: 'Farm', label: 'Materials' },
    Emissions: { analysisGroup: 'Farm', label: 'Emissions' },
    'Manure Boundary Expansion': {
      analysisGroup: 'Farm',
      label: 'Manure boundary expansion',
    },
    'Outbound transports': {
      analysisGroup: 'Transport',
      label: 'Outbound transports',
    },
    'Auxiliary Inputs': {
      analysisGroup: 'Auxiliary Inputs',
      label: 'Auxiliary Inputs',
    },
    'Emissions cow': {
      analysisGroup: 'Farm',
      label: 'Emissions cow',
    },
    'Feed cow': {
      analysisGroup: 'Ration',
      label: 'Feed cow',
    },
    'Bedding cow': {
      analysisGroup: 'Farm',
      label: 'Bedding cow',
    },
    'Emissions calf': {
      analysisGroup: 'Farm',
      label: 'Emissions cow',
    },
    'Feed calf': {
      analysisGroup: 'Ration',
      label: 'Feed cow',
    },
    'Bedding calf': {
      analysisGroup: 'Farm',
      label: 'Bedding cow',
    }
  },
  categoryNamingMapping: {
    Utilities: 'Resources',
    'Emissions cow': 'Emissions - Cow',
    'Feed cow': 'Feed - Cow',
    'Bedding cow': 'Bedding - Cow',
    'Emissions calf': 'Emissions - Calf',
    'Feed calf': 'Feed - Calf',
    'Bedding calf': 'Bedding - Calf',
  },
  units: {
    area: {
      hectare: {
        label: 'Ha',
      },
      m2: {
        label: 'm²',
      },
      km2: {
        label: 'km²',
      },
      acre: {
        label: 'Acre',
      },
      mu: {
        label: 'Mu',
      },
    },
    temperature: {
      degC: {
        label: '°C',
      },
      degF: {
        label: '°F',
      },
    },
    mass: {
      kg: {
        label: 'kg',
      },
      tonne: {
        label: 'tonne',
      },
      lbs: {
        label: 'lbs',
      },
      ton: {
        label: 'ton',
      },
    },
    massSmall: {
      g: {
        label: 'g',
      },
      oz: {
        label: 'oz',
      },
    },
    electricityUse: {
      MJ: {
        label: 'MJ',
      },
      kWh: {
        label: 'kWh',
      },
      BTU: {
        label: 'BTU',
      },
    },
    naturalGasUse: {
      MJ: {
        label: 'MJ',
      },
      kWh: {
        label: 'kWh',
      },
      gasm3: {
        label: 'm³',
      },
      thm: {
        label: 'US Therm',
      },
      BTU: {
        label: 'BTU',
      },
      gasft3: {
        label: 'ft³',
      },
    },
    dieselUse: {
      MJ: {
        label: 'MJ',
      },
      kWh: {
        label: 'kWh',
      },
      diesellitre: {
        label: 'l',
      },
      dieselUSGallon: {
        label: 'gal',
      },
      thm: {
        label: 'US Therm',
      },
      BTU: {
        label: 'BTU',
      },
    },
    gasolineUse: {
      MJ: {
        label: 'MJ',
      },
      kWh: {
        label: 'kWh',
      },
      gasolinelitre: {
        label: 'l',
      },
      gasolineUSgallon: {
        label: 'gal',
      },
      thm: {
        label: 'US Therm',
      },
      BTU: {
        label: 'BTU',
      },
    },
    heavyFuelOilUse: {
      MJ: {
        label: 'MJ',
      },
      kWh: {
        label: 'kWh',
      },
      heavyFuelOillitre: {
        label: 'l',
      },
      heavyFuelOilUSgallon: {
        label: 'gal',
      },
      thm: {
        label: 'US Therm',
      },
      BTU: {
        label: 'BTU',
      },
    },
    waterUse: {
      l: {
        label: 'l',
      },
      m3: {
        label: 'm³',
      },
      usgal: {
        label: 'US gallon',
      },
    },
    heatUse: {
      MJ: {
        label: 'MJ',
      },
      kWh: {
        label: 'kWh',
      },
      thm: {
        label: 'US Therm',
      },
      BTU: {
        label: 'BTU',
      },
    },
    impactCalcMass: {
      kg: {
        label: 'kg',
      },
      lb: {
        label: 'lb',
      },
    },
    unitIngredientQuantityInputMass: {
      kg: {
        label: 'kg',
      },
      lb: {
        label: 'lb',
      },
    },
    unitCompoundFeedNutritionalDataContent: {
      gperkg: {
        label: 'g/kg',
      },
      ozperlb: {
        label: 'oz/lb',
      },
    },
    unitCompoundFeedOutputMass: {
      tonne: {
        label: 'tonne',
      },
      ton: {
        label: 'ton',
      },
    },
    unitTransportDistanceTerrestrial: {
      km: {
        label: 'km',
      },
      mile: {
        label: 'Statute Mile',
      },
    },
    unitTransportDistanceSea: {
      km: {
        label: 'km',
      },
      nauticalmile: {
        label: 'Nautical Mile',
      },
    },
    unitTransportDistanceInlandWaterways: {
      km: {
        label: 'km',
      },
      mile: {
        label: 'Statute Mile',
      },
      nauticalmile: {
        label: 'Nautical mile',
      },
    },
    unitCompoundFeedWaterUse: {
      l: {
        label: 'l',
      },
      m3: {
        label: 'm³',
      },
      usgal: {
        label: 'US gallon',
      },
    },
    unitNutritionalGrossEnergy: {
      MJ: {
        label: 'MJ',
      },
      kCal: {
        label: 'kCal',
      },
    },
  },
  materials: [
    {
      display_name: 'Polyethylene',
      value: 'polyethylene',
    },
    {
      display_name: 'Polystyrene',
      value: 'polystyrene',
    },
    {
      display_name: 'Polyvinylchloride',
      value: 'polyvinylchloride',
    },
    {
      display_name: 'Nylon',
      value: 'nylon',
    },
    {
      display_name: 'Polypropylene',
      value: 'polypropylene',
    },
    {
      display_name: 'Polyurethane',
      value: 'polyurethane',
    },
    {
      display_name: 'Cast iron',
      value: 'cast_iron',
    },
    {
      display_name: 'Steel, chromium steel',
      value: 'chromium_steel',
    },
    {
      display_name: 'Steel, low-alloyed',
      value: 'low_alloyed_steel',
    },
    {
      display_name: 'Concrete block',
      value: 'concrete_block',
    },
    {
      display_name: 'Lead',
      value: 'lead',
    },
  ],
  antibiotics: [
    {
      display_name: 'Antibiotic/Minerals/Vitamins complex',
      value: 'total_minerals_additives_vitamins',
    },
  ],
  fuelTypes: [
    {
      display_name: 'Diesel',
      value: 'diesel',
    },
    {
      display_name: 'Electricity',
      value: 'electricity',
    },
  ],
  waterSourceTypes: [
    {
      display_name: 'Ground water',
      value: 'ground_water',
    },
    {
      display_name: 'Surface water',
      value: 'surface_water',
    },
  ],
  liceTreatment: [
    {
      display_name: 'Hydrogen peroxide',
      value: 'hydrogen_peroxide',
    },
    {
      display_name: 'Cleaner fish',
      value: 'cleaner_fish',
    },
    {
      display_name: 'Other',
      value: 'other',
    },
  ],
  nutrient_type: [
    {
      display_name: 'Crude protein',
      value: 'crude_protein',
      mandatory: true,
      unit: '%',
    },
    {
      display_name: 'Crude lipid',
      value: 'crude_lipid',
      mandatory: true,
      unit: '%',
    },
    {
      display_name: 'Crude fibre',
      value: 'crude_fibre',
      mandatory: true,
      unit: '%',
    },
    {
      display_name: 'Starch',
      value: 'starch',
      mandatory: true,
      unit: '%',
    },
    {
      display_name: 'Sugar',
      value: 'sugar',
      mandatory: true,
      unit: '%',
    },
    {
      display_name: 'Phosphorus',
      value: 'phosphorus',
      mandatory: true,
      unit: '%',
    },
    {
      display_name: 'Gross energy',
      value: 'gross_energy',
      unit: 'MJ/kg',
    },
    {
      display_name: 'Digestible energy',
      value: 'digestible_energy',
      unit: '%',
    },
    {
      display_name: 'Digestible nitrogen',
      value: 'digestible_nitrogen',
      unit: '%',
    },
    {
      display_name: 'Digestible phosphorus',
      value: 'digestible_phosphorus',
      unit: '%',
    },
    {
      display_name: 'Digestible carbon',
      value: 'digestible_carbon',
      unit: '%',
    },
    {
      display_name: 'Soluble nitrogen',
      value: 'soluble_nitrogen',
      unit: '%',
    },
    {
      display_name: 'Soluble phosphorus',
      value: 'soluble_phosphorus',
      unit: '%',
    },
    {
      display_name: 'Soluble carbon',
      value: 'soluble_carbon',
      unit: '%',
    },
    {
      display_name: 'Feed spill',
      value: 'feed_spill',
      unit: '%',
    },
    {
      display_name: 'Dry matter',
      value: 'dm_feed',
      unit: '%',
      mandatory: true,
    },
  ],
  ingredients: {
    compoundFeedIngredients: {
      '77e96c4f-640e-4848-a582-fe500b6c3a6e': {
        name: 'VevoVitall® DSM',
        localTest: true,
      },
      'bc79f197-0bdf-4012-a4e7-b908aa4a745e': {
        name: 'ROVIMIX® HyD® DSM',
        localTest: true,
      },
      '686bbfc8-1e26-496c-b928-4ba3a083ee42': {
        name: 'RONOZYME® VP (CT) DSM [Sustell] - Obsolete',
        localTest: true,
      },
      'e84afe21-67e8-4988-8eff-bab1f8489572': {
        name: 'ROVIMIX® β-Carotene 10% DSM [Sustell] - Obsolete',
        localTest: true,
      },
      '0c425649-a4cc-4a14-800a-184fe45fd5a4': {
        name: 'ROVIMIX® Biotin HP DSM [Sustell] - Obsolete',
        localTest: true,
      },
      'db23a82e-f114-439f-9420-d099b61fdc39': {
        name: 'Vitamins and Minerals Premix',
        localTest: true,
      },
      '136f62f7-3e16-4ae3-8ef2-0eb78102dd74': {
        name: 'RONOZYME® WX 2000 (CT) DSM [Sustell] - Obsolete',
        localTest: true,
      },
      '79f6adb9-1738-4b66-b103-7acdbef033ab': {
        name: 'RONOZYME® HiPhos (GT) DSM [Sustell] - Obsolete',
        localTest: true,
      },
      'c9ed719e-61a1-4ffe-a6c3-805f7ed0908f': {
        name: 'RONOZYME® ProAct (CT) DSM [Sustell] - Obsolete',
        localTest: true,
      },
      '237553d3-d117-44ce-8360-c903334b1f9b': {
        name: 'Mineral premix broilers 0.5 kg/ton',
        localTest: true,
      },
      'f75a0534-e300-4497-9849-fc2e9853ff23': {
        name: 'Vitamin premix broilers OVN 0.5 kg/ton',
        localTest: true,
      },
      '1689fbf4-51bf-452d-a2e6-2ca94e480b2a': {
        name: 'Choline chloride (70% dry)',
        localTest: true,
      },
      '02c68352-725f-431d-94b2-03ec0b52f586': {
        name: 'Sodium Bicarbonate',
        localTest: true,
      },
      'a4d878de-ccd4-43f7-98a2-35f5db4f204d': {
        name: 'Monocalcium Phosphate (CaH4P2O8)',
        localTest: true,
      },
      '93338894-a8ea-4e93-81b9-0fb163f54de3': { name: 'Vevowin' },
      '254f9846-a8f1-46a4-a70a-4f5ee34ec69c': {
        name: 'VevoVitall® - Obsolete',
      },
      'f59b895b-ec8d-41df-aa7d-6823f25a7f84': { name: 'CRINA® Ruminants' },
      'b6887b5d-049c-4e40-9711-7ecc7a3e3943': { name: 'Dicalcium phosphate' },
      '2924bdc4-f9fd-4e60-857d-73b96465d44c': { name: 'ROVIMIX® Biotin' },
      '47261230-95ae-4188-80cf-eafc84172ac7': {
        name: 'Vitamin premix LEAP roadtesting pigs',
      },
      'edb10009-3bbb-4998-9345-7f26fdd33939': {
        name: 'Vitamin premix LEAP roadtesting broilers',
      },
      'd2516f96-049f-47fb-b16b-04c469f37382': {
        name: 'ROVIMIX® D3 500 - Obsolete',
      },
      '9a815e15-8e67-474e-b6c6-16c70532da90': {
        name: 'ROVIMIX® β-Carotene 10% - Obsolete',
      },
      '1c2b15ae-edeb-4055-a94b-b88459f046ec': {
        name: 'ROVIMIX® HyD® - Obsolete',
      },
      '98b3073a-4022-4cc8-885e-f8c5bfc55330': {
        name: 'ROVIMIX® E-50 Adsorbate - Obsolete',
      },
      'df14693b-ca51-4e01-9568-fb990c140e5f': {
        name: 'Minerals Premix LEAP roadtesting pigs',
      },
      '774be50a-0860-46cd-886d-dd52a27b6a4b': {
        name: 'Minerals premix LEAP roadtesting broilers',
      },
      '6233749f-c27f-43b9-aea3-25ed390b5881': { name: 'CRINA® Poultry Plus' },
      '59ce7555-4990-4f4c-84a7-edf25a2b5720': { name: 'Choline chloride' },
      'b698c3b7-004c-46ec-bed9-b5ff879919d1': {
        name: 'ROVIMIX® Stay C-35 - Obsolete',
      },
      '69232478-4226-4e58-adfc-aed95c12c685': { name: 'Monocalcium phosphate' },
      '5e4aa0bf-dff1-4886-a171-55dbeed9c470': {
        name: 'RONOZYME® WX 2000 (CT) - Obsolete',
      },
      '438210f0-455f-4777-9916-3e0646854b4a': {
        name: 'RONOZYME® Multigrain (GT)',
      },
      '2c24f40f-e293-437a-998f-220e8f2a3b9f': { name: 'RONOZYME® VP (CT)' },
      'f27868de-a790-4073-b1ab-dcc872e39b0c': {
        name: 'RONOZYME® Rumistar 600 (CT) - Obsolete',
      },
      'ec42f7f8-0df7-4751-895f-3129a149cd08': {
        name: 'RONOZYME® ProAct (CT) - Obsolete',
      },
      '09cc47d2-2a2f-4df5-90b3-235eebd7a4d5': {
        name: 'RONOZYME® HiPhos 20000 (GT)',
      },
      '7a34a473-a712-47c5-ad8c-18febb814ebe': {
        name: 'RONOZYME® HiPhos (GT) DSM 5014131',
      },
      '532311ad-7472-4055-a683-034791edabd7': { name: 'Balancius® (C)' },
      'b44b9f6b-655f-4451-bdf2-0cf26fa670d2': { name: 'Bovaer® - Obsolete' },
      'c590c2e9-811d-4ddf-8be8-4e3469633476': { name: 'ROVIMIX® D3 500' },
      '0910a647-72ce-44e0-988c-edfc99b43745': {
        name: 'ROVIMIX® β-Carotene 10%',
      },
      'a081819f-ce25-4b7e-9cc5-81a57c801a33': { name: 'ROVIMIX® HyD®' },
      '3c2eb863-7bd9-4313-9512-0a8fc7b3fd4f': {
        name: 'ROVIMIX® E-50 Adsorbate',
      },
      '141a26c2-ea6f-4de4-b07d-75da025da42e': { name: 'ROVIMIX® Stay C-35' },
      'c4a954a7-e5cf-4abc-a24f-037a26b64d9e': {
        name: 'RONOZYME® WX 2000 (CT)',
      },
      'db8c76de-a256-4986-84a9-233142f7f7db': {
        name: 'RONOZYME® RumiStar 600 (CT)',
      },
      'f4151129-a297-4292-9578-bb603e4b16b0': { name: 'RONOZYME® ProAct (CT)' },
      '7c06f23f-0455-4aba-b403-3a5e3376ae66': { name: 'Bovaer®' },
      '732da5ae-fa39-485a-ad31-08884dfc1466': { name: 'HiPhorius™ 40' },
      '4ad8d9b9-1402-40e8-ab41-c8141b77f84a': { name: 'ProAct 360™' },
      '88569961-1cc4-4395-8247-4bf9077a8f4e': {
        name: 'RONOZYME® HiStarch 900 (CT)',
      },
      '1d69de50-32ea-4707-8f9d-790cb2cb7353': { name: 'Balancius® (L)' },
      'd44c17da-42bc-4bbe-b547-506891e3e880': { name: 'CAROPHYLL® Pink 8%' },
      '7c257f5b-3bfb-4dc1-9275-2fefcdeaa50a': { name: 'CAROPHYLL® Pink 10%' },
      'b65543f7-e34a-40e4-9d06-8b43bf5cc6e1': { name: 'CAROPHYLL® Red 10%' },
      '4d9e5342-9fa5-48b1-8087-34e57efec0c5': { name: 'CAROPHYLL® Yellow 10%' },
      'd0e94b82-40ff-4db3-bd8a-a4add7a3089d': { name: 'RONOZYME® HiPhos (L)' },
      '8ab1d60e-12f4-4e8e-8eab-11a606ef7674': { name: 'RONOZYME® HiPhos (M)' },
      '597ab691-b7fe-41fe-a33d-c18007e7542e': {
        name: 'RONOZYME® HiPhos 5000 (GT)',
      },
      '4a1b5a50-4c53-41e2-9008-d244907c2b01': {
        name: 'RONOZYME® HiStarch (CT)',
      },
      'ca5cf58c-7192-4c9c-a7b6-e28082697e6a': {
        name: 'RONOZYME® Multigrain (L)',
      },
      '3c4ffe7d-8705-433b-9751-21d1f0aaa453': { name: 'RONOZYME® ProAct (L)' },
      '5019914f-b8c7-4d3d-b0a8-da79146c7405': {
        name: 'RONOZYME® RumiStar 900 (L)',
      },
      '20db711d-e3e8-4409-8467-314cfd7ef37d': { name: 'RONOZYME® WX 2000 (L)' },
      'dddb983c-c2e7-44e6-901f-0eea949d580b': {
        name: 'RONOZYME® WX 5000 (CT)',
      },
      '71ce6d12-f72d-4a37-b352-c0628b46a4ef': { name: 'ROVIMIX® A 1000' },
      '8682df56-ab0c-4e8d-a86f-13bd2097a485': { name: 'ROVIMIX® A 500' },
      'eb91580e-4a51-4be2-9373-c385aec548b3': { name: 'ROVIMIX® AD3 1000/200' },
      'b781cbab-56b9-4d31-ac68-1a9dbc9a784b': { name: 'ROVIMIX® B2 80-SD' },
      '4c61db95-aff2-4293-b574-ffe8c4a9034a': { name: 'ROVIMIX® B6' },
      '38e6e11f-1a10-4621-adbc-ecdf1545bd1c': { name: 'ROVIMIX® Calpan' },
      '3410d597-7770-42c6-942f-3dd54b081606': { name: 'ROVIMIX® Folic 80-SD' },
      'd08dab8a-cb8f-452c-97de-237e965e25a8': { name: 'ROVIMIX® Niacin' },
      '130290f8-03ce-4047-ae68-ec3fe583a49c': { name: 'ROVIMIX® Niacinamide' },
      '1de2c3b8-e308-40a9-b3a1-d407873f8add': { name: 'DHA Gold®' },
      '766bcfe0-9e53-414c-bcf9-e3c1c7bbaf70': { name: 'VevoVitall®' },
      '1b9f359b-9e50-4b98-bbff-d15e736f840f': {
        name: 'Soybeans (Donau Soja) Ukraine',
      },
      '69c2c5c2-ff21-46d6-8bb4-0f98db0a3e90': {
        name: 'Soybeans (Donau Soja) Serbia',
      },
      'c0602d63-b40b-4c75-9758-8aa31cb6c693': {
        name: 'Soybeans (Donau Soja) Romania',
      },
      '71d395f9-3bba-4558-9905-d57d12b31c1b': {
        name: 'Soybeans (Donau Soja) Croatia',
      },
      'e073ac25-57e9-4e8f-acb6-cac2bca715d6': { name: 'Valine FR' },
      'fe272930-e4c4-4a20-8784-82f9d9672260': { name: 'Valine CN' },
      '7a680fb7-dfa9-4456-be04-a99354b1dc45': { name: 'Tryptophane KR' },
      'a9be8b57-4f68-4e1a-97bd-e43e99bc828c': { name: 'Tryptophane ID' },
      '2cc90b03-8508-4d98-b9ca-6583cdacd4cf': { name: 'Tryptophane FR' },
      '0833eca6-62c4-48d7-ae38-2dd760c342ff': { name: 'Tryptophane CN' },
      '80c403e4-4f44-491f-a1ee-b150d91f6c86': { name: 'Threonine CN' },
      'c7eaf360-157b-4185-b062-671ee84b192b': { name: 'Lysine Sulfate US' },
      '4bb98286-9752-4a1b-85e2-e823571a7548': { name: 'Lysine Sulfate ID' },
      'ec1b32d8-7e2c-40c5-834c-97f2b8cb7f4c': { name: 'Lysine Sulfate CN' },
      'bc4d47ec-793e-418f-a4f9-868b082f7942': { name: 'Lysine Solid (HCl) US' },
      'f405e468-7d47-4f2e-a193-2d97143b5137': { name: 'Lysine Solid (HCl) KR' },
      '5137aa52-d45e-4739-8b9c-7b6027126b19': { name: 'Lysine Solid (HCl) ID' },
      '5c68f468-9710-45ed-aaf3-5de72eb958ff': { name: 'Lysine Solid (HCl) FR' },
      'e370849d-81bf-441d-a47d-de41ec305390': { name: 'Lysine Solid (HCl) CN' },
      'b53eb412-bc31-45b2-8c9d-cbc4e52847b6': { name: 'Lysine (liquid) US' },
      'e9ca3f0e-ecd2-4bf5-9759-fe9c6c4c6bd4': { name: 'Lysine (liquid) KR' },
      'cae2334e-a6ca-4de9-a4ff-7f005455d565': { name: 'Lysine (liquid) ID' },
      '6a4f9740-d5de-43d9-87bf-af783324b42c': { name: 'Lysine (liquid) FR' },
      '5d333434-dd20-427a-8edc-97b6a8d37324': { name: 'Leucine FR' },
      'cbfb9f3d-590e-4b0c-9c05-2fef56a0930f': { name: 'Isoleucine FR' },
      'f378ee51-5a7b-4a9d-883d-bb42ca650530': { name: 'Isoleucine CN' },
      'a3db1c18-ee49-434f-871f-1b65340c7be7': { name: 'Histidine ID' },
      '7743a7d1-2031-43e1-a6f4-a316e81fabfd': { name: 'Histidine BR' },
      'a67a52f0-c430-4c09-bc4a-25c0d237e982': { name: 'Arginine KR' },
      'e8cc2e7d-4e9d-4e70-8b29-3e5e401d61d8': { name: 'Arginine FR' },
      'f0d0f768-4485-48a8-a705-62eafe01b4d6': { name: 'Arginine CN' },
      'c2aa677d-9854-4970-ab91-652b0575772c': { name: 'Arginine BR' },
    },
    singleIngredients: {
      '2443424d-8fa4-4dd9-9228-92c04e96e3a9': {
        name: 'RONOZYME® RumiStar 600 (CT) DSM',
        localTest: true,
      },
      '2c666f29-9d2c-4eb6-a1e2-78bf688cf0d7': {
        name: 'RONOZYME® RumiStar 900 (L) DSM',
        localTest: true,
      }, // Ingredients marked with localTest=true are for local enironment
      '1f547ecc-144a-45ad-9f95-d4dd91be140d': {
        name: 'Rovimix Stay C-35 (DSM-0483044) - Obsolete',
      },
      '2374c667-f18e-42e1-afb7-73e36011fd3b': {
        name: 'Rovimix HyD 1.25% (DSM-5015874) - Obsolete',
      },
      '26233c76-5378-4ca7-bf4d-5e4f06a203dd': {
        name: 'RONOZYME® ProAct (CT) (DSM 5009251) - Obsolete',
      },
      '2c26d325-37d4-48d6-a3c6-60e3346a0de6': {
        name: 'Crina Ruminants (DSM-5014722)',
      },
      '4072b9cf-e152-47ff-b6ce-a3978025e888': {
        name: 'RONOZYME® WX 2000 (CT) - Obsolete',
      },
      '575e5867-2a89-4e23-aab3-3fa2fd8eac93': {
        name: 'RONOZYME® HiPhos 20000 (GT) (DSM 5015795)',
      },
      '5b4b2e50-ac40-42ff-b26a-033b10470829': {
        name: 'Balancius® (C)  (DSM 5016676)',
      },
      '5d0edf0c-515b-4bb7-8454-e42320df0de4': {
        name: 'RONOZYME® Multigrain (GT) (DSM-5015312)',
      },
      '64114aa3-7ee6-4a09-b780-3a9170330ce0': {
        name: 'ROVIMIX® D3 500 (DSM-5016016) - Obsolete',
      },
      '6f10cf57-4102-4630-95d9-98ca7b5b7b45': {
        name: 'RONOZYME® Rumistar 600 (CT) (DSM 5011272) - Obsolete',
      },
      'a4f3865c-ec57-4cc2-bffb-2d98fba4b459': {
        name: 'ROVIMIX® β-Carotene 10% (DSM-5016030) - Obsolete',
      },
      'c5060f02-fd03-43c9-ac21-0ab425f582eb': {
        name: 'RONOZYME® HiPhos (GT) (DSM 5014131)',
      },
      'd553ce4c-4934-45d9-b3cd-9a3d0105817e': {
        name: 'RONOZYME® VP (CT) (DSM-0485535)',
      },
      'd6568a70-e19e-4427-a942-3558b443c300': {
        name: 'ROVIMIX® Biotin HP (DSM-5005582)',
      },
      'dd0acf21-facd-41e9-9ec5-eaa0d32524b2': {
        name: 'ROVIMIX® E-50 Adsorbate - Obsolete',
      },
      'e03a0c37-2f58-4191-8c7d-fc96e741591a': { name: 'Bovaer® - Obsolete' },
      'e5b0d4bc-f8dc-475f-824f-962dd94f64b9': {
        name: 'VevoWin® (DSM 5015718)',
      },
      'eb7f4897-57df-4874-aa63-496b986f9f79': {
        name: 'VevoVitall® (DSM 5003601) - Obsolete',
      },
      'f22ad13c-b56c-4173-b7c2-f30d6aa379fc': {
        name: 'CRINA® Poultry Plus (DSM-5010861)',
      },
      '6b959bf6-ad17-4745-a94e-9471387376a1': { name: 'ROVIMIX® D3 500' },
      '4d530296-5ffd-456c-b0cf-799c0a88cd53': {
        name: 'ROVIMIX® β-Carotene 10%',
      },
      'dd60bb3c-410b-4694-be98-5df3c1aee1cb': { name: 'ROVIMIX® HyD®' },
      'c5a869ec-eac6-464c-9837-835a5f163d82': {
        name: 'ROVIMIX® E-50 Adsorbate',
      },
      '9a3fe81b-d06b-4f20-a3fa-05b516e46caf': { name: 'ROVIMIX® Stay C-35' },
      'a9b33195-3795-409f-9784-8bf116d69209': {
        name: 'RONOZYME® WX 2000 (CT)',
      },
      '84359949-8cb1-4f49-8e40-fed815b6d59c': {
        name: 'RONOZYME® RumiStar 600 (CT)',
      },
      'a7f00da1-b317-4360-b985-d5393661489e': { name: 'RONOZYME® ProAct (CT)' },
      'ec57ad7e-f5d0-4b12-af83-0ff075645658': { name: 'Bovaer®' },
      '25c1acbe-18d0-479e-b4d0-0cb94dfd2e2c': { name: 'HiPhorius™ 40' },
      '41661c9b-d7de-48f4-a29f-081bec9e101c': { name: 'ProAct 360™' },
      'dd90bfee-8036-4855-b22c-557595f313ed': {
        name: 'RONOZYME® HiStarch 900 (CT)',
      },
      '06ad2f12-0ebd-4a0d-a6c8-1162ac00bd41': { name: 'Balancius® (L)' },
      '48143178-5f88-4c08-be77-5c2d099f4a93': { name: 'CAROPHYLL® Red 10%' },
      '9586e262-0b96-4cc5-bdf5-ee6a06326e33': { name: 'CAROPHYLL® Yellow 10%' },
      '5f4013d5-47fe-4210-aaa5-361832908eff': { name: 'RONOZYME® HiPhos (L)' },
      '453ac7da-8a15-4c18-8e25-e09d1e77897e': { name: 'RONOZYME® HiPhos (M)' },
      'aaec2dae-e190-487e-8386-6dda9cca4ef3': {
        name: 'RONOZYME® HiPhos 5000 (GT)',
      },
      '88d00555-0d93-4a98-90e2-6b6579ee714b': {
        name: 'RONOZYME® HiStarch (CT)',
      },
      'ccf6ecc0-5fc3-4f22-b8a4-231f81bbe4b5': {
        name: 'RONOZYME® Multigrain (L)',
      },
      '6146d55a-b483-46ea-b064-6199ca150932': { name: 'RONOZYME® ProAct (L)' },
      'c09de364-0dde-49e8-8109-e1a938b20c93': {
        name: 'RONOZYME® RumiStar 900 (L)',
      },
      '9daf58bc-40c9-4ada-b67c-c834c69a9807': { name: 'RONOZYME® WX 2000 (L)' },
      'fa2a73fa-3cd6-4f36-bda7-522ac7c55ab3': {
        name: 'RONOZYME® WX 5000 (CT)',
      },
      'da413082-29a0-482f-bcb9-3e3489af0cf3': { name: 'ROVIMIX® A 1000' },
      '8afdf0e7-1936-46d1-b6bf-1a4851c3fa83': { name: 'ROVIMIX® A 500' },
      'c04adde9-0f04-4a73-b175-be0a43abc8c6': { name: 'ROVIMIX® AD3 1000/200' },
      '6b6912b6-7603-4961-8bb0-a1a160a8fa9a': { name: 'ROVIMIX® B2 80-SD' },
      'f7c2e78c-f91b-47ea-b5c8-f80d50851ba2': { name: 'ROVIMIX® B6' },
      'db70dc0a-6897-4e30-98b1-025815bc095a': { name: 'ROVIMIX® Calpan' },
      '9439d22b-8ed0-4847-87d2-f8ef3ae9ce27': { name: 'ROVIMIX® Folic 80-SD' },
      '59f535cd-b218-473a-9059-57b142723db1': { name: 'ROVIMIX® Niacin' },
      'f21a793b-819c-497a-ae53-789b3ef07877': { name: 'ROVIMIX® Niacinamide' },
      '9b73a70d-9d55-4b9b-bdd7-ab99dfca7a7b': { name: 'DHA Gold®' },
      'd6dd02b1-04e5-433c-997a-557b19c64f85': { name: 'VevoVitall®' },
    },
  },
  selfGeneratedRenewablesList: [
    {
      enumVal: ResourceUseType.ElectricityPhotovoltaic,
      fieldName: 'electricityPhotovoltaicUse',
    },
    {
      enumVal: ResourceUseType.ElectricityWind,
      fieldName: 'electricityWindUse',
    },
  ],
  energyTypesList: [
    {
      enumVal: ResourceUseType.Diesel,
      fieldName: 'dieselUse',
    },
    {
      enumVal: ResourceUseType.Gasoline,
      fieldName: 'gasolineUse',
    },
    {
      enumVal: ResourceUseType.HeavyFuelOil,
      fieldName: 'heavyFuelOilUse',
    },
    {
      enumVal: ResourceUseType.PropaneOrLpg,
      fieldName: 'propaneOrLpgUse',
    },
    {
      enumVal: ResourceUseType.Coal,
      fieldName: 'coalUse',
    },
  ],
  speciesPerDatabaseFoundation: {
    [CFDatabaseFoundation.AFP_5]: [
      'Dairy system',
    ],
    [CFDatabaseFoundation.AFP_6_3]: [
      'Marine fish system',
      'Pig system',
      'Broiler system',
      'Layer system',
      'Turkey system',
      'Beef system',
      'Shrimp system',
      'DairyV2 system',
    ],
    [CFDatabaseFoundation.GFLI]: [
      'Marine fish system',
      'Pig system',
      'Broiler system',
      'Layer system',
      'Turkey system',
      'Beef system',
      'Shrimp system',
      'DairyV2 system',
    ],
  },
  databaseFoundationsPerSpecie: {
    'Dairy system': [CFDatabaseFoundation.AFP_5],
    'Broiler system': [
      CFDatabaseFoundation.AFP_6_3,
      CFDatabaseFoundation.GFLI,
    ],
    'Layer system': [
      CFDatabaseFoundation.AFP_6_3,
      CFDatabaseFoundation.GFLI,
    ],
    'Turkey system': [CFDatabaseFoundation.AFP_6_3, CFDatabaseFoundation.GFLI],
    'Pig system': [CFDatabaseFoundation.AFP_6_3, CFDatabaseFoundation.GFLI],
    'Marine fish system': [
      CFDatabaseFoundation.AFP_6_3,
      CFDatabaseFoundation.GFLI,
    ],
    'Poultry system': [
      CFDatabaseFoundation.AFP_6_3,
      CFDatabaseFoundation.GFLI,
    ],
    'Shrimp system': [
      CFDatabaseFoundation.AFP_6_3,
      CFDatabaseFoundation.GFLI
    ],
    'Beef system': [
      CFDatabaseFoundation.AFP_6_3,
      CFDatabaseFoundation.GFLI,
    ],
    'DairyV2 system': [
      CFDatabaseFoundation.AFP_6_3,
      CFDatabaseFoundation.GFLI,
    ],
  },
  defaultDatabaseFoundationPerSpecie: {
    'Dairy system': CFDatabaseFoundation.AFP_5,
    'Broiler system': CFDatabaseFoundation.AFP_6_3,
    'Layer system': CFDatabaseFoundation.AFP_6_3,
    'Pig system': CFDatabaseFoundation.AFP_6_3,
    'Marine fish system': CFDatabaseFoundation.AFP_6_3,
    'Turkey system': CFDatabaseFoundation.AFP_6_3,
    'Shrimp system': CFDatabaseFoundation.AFP_6_3,
    'Beef system': CFDatabaseFoundation.AFP_6_3,
    'DairyV2 system': CFDatabaseFoundation.AFP_6_3,
  },
  databaseFoundationsForFeedsV2: [
    CFDatabaseFoundation.GFLI,
    CFDatabaseFoundation.AFP_6_3,
  ],
  databasesForFeedsV2: [
    CompoundFeedDatabase.Gfli,
    CompoundFeedDatabase.Afp_6_3,
  ],
  databaseSelection: {
    GFLI: {
      label: 'GFLI 2.0',
    },
    AFP_6_3: {
      label: 'AFP 6.3',
    },
  },
  animalTypeFeedAnimalSystem: {
    PIG: 'Pig system',
    SALMON_V2: 'Marine fish system',
    SEA_BASS: 'Marine fish system',
    SEA_BREAM: 'Marine fish system',
    BROILER_V2: 'Broiler system',
    LAYING_HENS_V2: 'Layer system',
    TURKEY: 'Turkey system',
    'LITOPENAEUS_VANNAMEI': 'Shrimp system',
    'PENAEUS_MONODON': 'Shrimp system',
    BEEF: 'Beef system',
    DAIRY_V2: 'DairyV2 system',
  },
  oldSystems: [
    'Dairy system',
    'Broiler system',
    'Layer system',
    'Salmon system',
  ],
  defaultIngredientsAddons: [
    {
      addonType: 'DONAU_SOJA',
      ingredients: [
        {
          name: 'Soybean, dried, full fat (Donau Soja)',
          origin: 'HR',
        },
        {
          name: 'Soybean, dried, full fat (Donau Soja)',
          origin: 'RO',
        },
        {
          name: 'Soybean, dried, full fat (Donau Soja)',
          origin: 'RS',
        },
        {
          name: 'Soybean, dried, full fat (Donau Soja)',
          origin: 'UA',
        },
      ],
    },
    {
      addonType: 'EVONIK',
      ingredients: [
        {
          name: 'MetAMINO®, 99% DL-Methionine',
          origin: 'BE',
        },
        {
          name: 'Biolys®, 54.6% L-Lysine',
          origin: 'US',
        },
        {
          name: 'ThreAMINO®, 98.5% L-Threonine',
          origin: 'HU',
        },
        {
          name: 'TrypAMINO®, 98.0% L-Tryptophan',
          origin: 'SK',
        },
      ],
    },

    {
      addonType: 'EMBRAPA',
      ingredients: [
        {
          name: 'Crude soybean oil (solvent, from soybean meal 46% protein)',
          origin: 'BR',
        },
        {
          name: 'Soybean meal - 48% protein content (solvent)',
          origin: 'BR',
        },
        {
          name: 'Maize/Corn, winter, dried',
          origin: 'BR',
        },
        {
          name: 'Soybean, dried, full fat',
          origin: 'BR',
        },
        {
          name: 'Soybean meal - 46% protein content (solvent)',
          origin: 'BR',
        },
        {
          name: 'Raw sugar, from sugar cane, at modern processing',
          origin: 'BR',
        },
        {
          name: 'Crude soybean oil (solvent, from soybean meal 48% protein)',
          origin: 'BR',
        },
        {
          name: 'Maize/Corn, dried',
          origin: 'BR',
        },
        {
          name: 'Maize/Corn, summer, dried',
          origin: 'BR',
        },
        {
          name: 'Soybean hulls toasted',
          origin: 'BR',
        },
        {
          name: 'Sugar cane',
          origin: 'BR',
        },
      ],
    },
    {
      addonType: 'DSM',
      ingredients: [
        {
          name: 'Dicalcium phosphate',
          origin: 'GLO',
        },
        {
          name: 'Choline chloride 70% dry',
          origin: 'GLO',
        },
      ],
    },
  ],
  manureManagementDescriptionsPoultry: {
    BREEDING: [
      'POULTRY_MANURE_WITH_OR_WITHOUT_LITTER',
      'AEROBIC_TREATMENT',
      'COMPOSTING_STATIC_PILE_FORCED_AERATION',
      'COMPOSTING_INTENSIVE_WINDROW_FREQUENT_TURNING',
      'COMPOSTING_IN_VESSEL',
      'COMPOSTING_PASSIVE_WINDROW_INFREQUENT_TURNING',
      'DAILY_SPREAD',
      'LIQUID_SLURRY',
      'PIT_STORAGE_BELOW_ANIMAL_CONFINEMENTS',
      'SOLID_STORAGE',
    ],
    GROWING: ['POULTRY_MANURE_WITH_OR_WITHOUT_LITTER', 'DAILY_SPREAD'],
    LAYING: [
      'POULTRY_MANURE_WITH_OR_WITHOUT_LITTER',
      'AEROBIC_TREATMENT',
      'COMPOSTING_STATIC_PILE_FORCED_AERATION',
      'COMPOSTING_INTENSIVE_WINDROW_FREQUENT_TURNING',
      'COMPOSTING_IN_VESSEL',
      'COMPOSTING_PASSIVE_WINDROW_INFREQUENT_TURNING',
      'DAILY_SPREAD',
      'LIQUID_SLURRY',
      'PIT_STORAGE_BELOW_ANIMAL_CONFINEMENTS',
      'SOLID_STORAGE',
    ],
  },
  defaultPrice: {
    BREEDING: {
      price: 2,
      priceEggs: 0.18,
    },
    HATCHING: {
      priceOfLossesSold: 0,
      price: 0.35,
    },
    LAYING: {
      priceEggs: 0.055,
      priceHens: 0.32,
    },
  },
  marineFishFeedDefaultValues: {
    feedSpill: '5',
    solubleNitrogen: '15',
    solublePhosphorous: '15',
    solubleCarbon: '15',
    digestibleNitrogen: '85',
    digestibleCarbon: '80',
    digestiblePhosphorous: '50',
  },
  shrimpOutputDefaultValues: {
    name: '',
    price: '',
    sold_externally: 'Yes',
    total_weight: '',
    average_weight_hectare: '',
    protein_content: '',
  },
  incidentLocation: {
    'My Farms': {
      label: 'My Farms',
    },
    'My Feeds': {
      label: 'My Feeds',
    },
    'My Dashboard': {
      label: 'My Dashboard',
    },
    'Other(Help Center, My Profile, Notification, etc.)': {
      label: 'Other (Help Center, My Profile, Notification, etc.)',
    },
    Unknown: {
      label: 'Unknown',
    },
  },
  incidentTypes: {
    'Calculation not working': {
      label: 'Calculation not working',
    },
    'Functionality not working': {
      label: 'Functionality not working',
    },
    'Data inputs incident': {
      label: 'Data inputs incident',
    },
    'Database incident': {
      label: 'Database incident',
    },
    Translation: {
      label: 'Translation',
    },
    'Questions/support needed': {
      label: 'Questions/support needed',
    },
    Other: {
      label: 'Other',
    },
  },
  speciesTypes: {
    Broiler: {
      label: 'Broiler',
    },
    Layer: {
      label: 'Layer',
    },
    'Marine Fish': {
      label: 'Marine Fish',
    },
    Pig: {
      label: 'Pig',
    },
    'Dairy Cow': {
      label: 'Dairy Cow',
    },
  },
  compoundFeedNutrientsDefault: {
    'Pig system': {
      dryMatterContent: 90,
      grossEnergyContent: 18.45,
      digestibleEnergyContent: 14.76,
      urinaryEnergyContent: 0.2952,
      ashContent: 6,
      phosphorusContent: 0.2,
      pContent: 0,
      metabolizableEnergyContent: 0,
      neutralDetergentFiber: 0,
    },
    'Broiler system': {
      dryMatterContent: 87,
      grossEnergyContent: 18.45,
      metabolizableEnergyContent: 14.76,
      ashContent: 10,
      pContent: 6,
      digestibleEnergyContent: 0,
      urinaryEnergyContent: 0,
      phosphorusContent: 0,
      neutralDetergentFiber: 0,
    },
    'Layer system': {
      dryMatterContent: 87,
      grossEnergyContent: 18.45,
      metabolizableEnergyContent: 12.92,
      ashContent: 10,
      pContent: 6,
      digestibleEnergyContent: 0,
      urinaryEnergyContent: 0,
      phosphorusContent: 0,
      neutralDetergentFiber: 0,
    },
    'Turkey system': {
      dryMatterContent: 87,
      grossEnergyContent: 18.45,
      metabolizableEnergyContent: 14.76,
      ashContent: 10,
      pContent: 6,
      digestibleEnergyContent: 0,
      urinaryEnergyContent: 0,
      phosphorusContent: 0,
      neutralDetergentFiber: 0,
    },
    'Beef system': {
      grossEnergyContent: 18.45,
      dryMatterContent: 86,
      digestibleEnergyContent: 78.5,
      urinaryEnergyContent: 4,
      ashContent: 10,
      phosphorusContent: 0,
      pContent: 0,
      metabolizableEnergyContent: 0,
      neutralDetergentFiber: 0,
    },
    'DairyV2 system': {
      grossEnergyContent: 18.45,
      dryMatterContent: 86,
      digestibleEnergyContent: 78.5,
      urinaryEnergyContent: 4,
      ashContent: 10,
      phosphorusContent: 0,
      pContent: 0,
      metabolizableEnergyContent: 0,
      neutralDetergentFiber: 30,
    },
  },
  shrimpProteinContentDefault: {
    'LITOPENAEUS_VANNAMEI': {
      value: 17.8,
    },
    'PENAEUS_MONODON': {
      value: 18.8
    },
  },
  naturalFeedProteinContentDefault: {
    'ARTEMIA': 52.2,
    'YEAST': 48.9,
  },
  shrimpStages: [
    'HATCHING',
    'NURSERY',
    'GROWING',
  ],
  shrimpInputSourceDefinitions: {
    GROWING: [
      'JUVENILES.SUPPLY',
      'JUVENILES.EXTERNAL_SOURCE',
    ],
    NURSERY: [
      'POST_LARVAE.SUPPLY',
      'POST_LARVAE.EXTERNAL_SOURCE',
    ]
  },
  stageDefinitions: {
    'POULTRY': [
      'GROWING',
      'BREEDING',
      'HATCHING',
      'LAYING',
      'PROCESSING',
    ],
    'PIG': [
      'FATTENING',
      'BREEDING',
      'PROCESSING'
    ],
    'BEEF': [
      'BREEDING',
      'GROWING',
      'PROCESSING',
    ],
    'DAIRY_V2': [
      'DAIRY_COW',
      'CONTINUOUS_GROWING',
    ],
  },
  cattleInputDefinitions: [
    'HIGH',
    'LOW',
  ],
  cattleMMSDefinitions: [
    'AEROBIC',
    'ANAEROBIC_HIGH',
    'ANAEROBIC_LOW',
    'DEEP_BEDDING',
    'COMPOSTING_INTENSIVE',
    'COMPOSTING_INVESSEL',
    'COMPOSTING_PASSIVE',
    'COMPOSTING_STATIC',
    'DAILY_SPREAD',
    'DRY_LOT',
    'LIQUID_SLURRY',
    'PASTURE',
    'PIT_STORAGE',
    'SOLID_STORAGE',
    'UNCOVERED_LAGOON',
  ],
  processingInputDefaultValues: {
    BEEF: {
      shrinkingRate: 0,
    },
  },
};

export default lookupValues;
