import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { Maybe } from 'graphql/jsutils/Maybe';

import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { FormType } from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { ShrimpBaseline } from '../../../../models/Baseline/ShrimpBaseline';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { ShrimpIntervention } from '../../../../models/Intervention/ShrimpIntervention';

interface ShrimpFertilizerFormProps {
    stageIndex?: number | undefined;
    itemIndex?: number | undefined;
    formType: FormType;
    removeHandler?: Maybe<Function>;
    required?: boolean;
    baseline: ShrimpBaseline;
    editEnabled: boolean;
}

const ShrimpFertilizerForm: FC<ShrimpFertilizerFormProps> = ({
    stageIndex = 0,
    itemIndex = 0,
    formType,
    removeHandler,
    required = false,
    editEnabled = true,
    baseline
}) => {
    const intl = useIntl();
    const classes = processAndStageStylesV2() as CSSClassesList;

    const propName = 'fertilizerTypes';
    const fieldItemPrefix = `stages.${stageIndex}.stageData.operations.${propName}.${itemIndex}`;

    const fc = useFormContext<ShrimpIntervention>();

    const fertilizerData = baseline?.stages[itemIndex]?.stageData?.operations?.fertilizerTypes[itemIndex];

    return (
        <>
           <input
                ref={fc.register()}
                type="hidden"
                name={`${fieldItemPrefix}.type`}
                value={fertilizerData?.type}
            />
            {!editEnabled && !fc.getValues(`${fieldItemPrefix}.amount`) ? (
                <>
                    <DsmGrid className={classes.dsmGridTwoColumn}>
                        <ReactHookDsmInput
                            name={`${fieldItemPrefix}.originalAmount`}
                            label={fertilizerData?.type as string}
                            tooltip={intl.formatMessage({
                                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.FERTILIZER_TYPE_AMOUNT.TOOLTIP',
                            })}
                            adornment={intl.formatMessage({
                                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.FERTILIZER_TYPE_AMOUNT.PLACEHOLDER',
                            })}
                            type="number"
                            disabled
                            required={required}
                            defaultValue={fertilizerData?.amount}
                        />
                    </DsmGrid>
                </>
            ) : (
                <DsmGrid className={classes.dsmGridTwoColumn}>
                    <DsmGrid className={classes.dsmGridOneColumn}>
                        <RowTextFieldWithMetrics
                            name={`${fieldItemPrefix}.amount`}
                            label={fertilizerData?.type as string}
                            type="number"
                            tooltip={intl.formatMessage({
                                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.FERTILIZER_TYPE_AMOUNT.TOOLTIP',
                            })}
                            metricUnit=''
                            baseline={fertilizerData?.amount as number}
                            disabled={formType === FormType.View || !editEnabled}
                            displayTypeSelect={editEnabled}
                        />
                    </DsmGrid>

                    {formType !== FormType.View && removeHandler && editEnabled &&(
                        <DsmButton
                            variant="text"
                            iconOnly
                            destructive
                            style={{ padding: '0px', marginTop: '24px' }}
                            onClick={() => removeHandler(itemIndex)}
                        >
                            <DsmIcon
                                slot='before'
                                name="general/trash-01"
                            />
                        </DsmButton>
                    )}
                </DsmGrid>
            )}
        </>
    );
};

export default ShrimpFertilizerForm;
