import { FC, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { FormType, ShrimpFarms } from "../../common";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import {
  ShrimpInput,
  ShrimpIntervention,
  ShrimpStageData,
} from "../../../../models/Intervention/ShrimpIntervention";
import {
  ShrimpBaseline,
  ShrimpGrowingStageData,
} from "../../../../models/Baseline/ShrimpBaseline";
import ShrimpInputDurationComponent from "./ShrimpInputDurationComponent";
import ShrimpInputPondComponent from "./ShrimpInputPondComponent";
import ShrimpInputStockComponent from "./ShrimpInputStockComponent";
import { AnimalType, StageType } from "../../../../../../graphql/types";
import DefinitionsDialog from "../../CommonDataParts/v2.0/DefinitionsDialog";
import lookupValues from "../../../../helpers/lookupValues";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import ShrimpInputSupplyComponent from "./ShrimpInputSupplyComponent";
import ShrimpInputExternalComponent from "./ShrimpInputExternalComponent";
import SGrowingSupplyForm from "../../Baseline/Shrimp/SGrowingSupplyForm";
import SGrowingExternalSourceForm from "../../Baseline/Shrimp/SGrowingExternalSourceForm";

interface ShrimpInputNurseryDialogProps {
  farms: ShrimpFarms[];
  formType: FormType;
  stageIndex: number;
  formVisible: boolean;
  handleCancel: (param?: string | null) => void;
  baseline: ShrimpBaseline;
  animalType: AnimalType.LitopenaeusVannamei | AnimalType.PenaeusMonodon;
  farmId: string;
  farmName: string;
  productionProcessName: string;
}

const ShrimpInputNurseryDialog: FC<ShrimpInputNurseryDialogProps> = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
  farms,
  animalType,
  farmId,
  farmName,
  productionProcessName,
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const intl = useIntl();

  const fieldItemPrefix = `stages.${stageIndex}.stageData.input`;
  const allowedStages = [StageType.Hatching, StageType.Nursery];
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const [isSupplyEdit, setIsSupplyEdit] = useState<boolean>(false);
  const [isExternalEdit, setIsExternalEdit] = useState<boolean>(false);
  const currResetValue = useRef<ShrimpInput>();
  const fc = useFormContext<ShrimpIntervention>();
  const { control } = fc;
  const currentStageData = baseline.stages[stageIndex]
    .stageData as ShrimpGrowingStageData;

  const {
    fields: internalSourcesAdditions,
    append: appendInternalAdditions,
    remove: removeInternalAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.internalSourcesAdditions`,
    control,
    keyName: "keyId",
  });
  const {
    fields: externalSourcesAdditions,
    append: appendExternalAdditions,
    remove: removeExternalAdditions,
  } = useFieldArray({
    name: `${fieldItemPrefix}.externalSourcesAdditions`,
    control,
    keyName: "keyId",
  });
  const addInternalSource = () => {
    appendInternalAdditions({
      id: uuidv4(),
      comingFromFarm: "",
      comingFromStage: "",
      outputName: "",
      totalWeight: "",
      distanceTraveled: "",
      transportMode: "",
    });
  };
  const addExternalSource = () => {
    appendExternalAdditions({
      id: uuidv4(),
      totalWeight: "",
      distanceTraveled: "",
      transportMode: "",
    });
  };

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as ShrimpIntervention;
      const stageData =
        (resetObject.stages?.[stageIndex]
          ?.stageData as unknown as ShrimpStageData) || {};
      if (resetObject?.stages?.[stageIndex])
        resetObject.stages[stageIndex].stageData = stageData;
      if (stageData?.input) {
        stageData.input = currResetValue.current;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) {
      handleCancel();
    }
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(fieldItemPrefix)) || {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const cancelSupplyEdit = () => {
    currentStageData.input?.internalSources?.forEach((_, index) => {
      fc.setValue(
        `${fieldItemPrefix}.internalSources.${index}.totalWeight`,
        ""
      );
      fc.setValue(
        `${fieldItemPrefix}.internalSources.${index}.distanceTraveled`,
        ""
      );
      fc.setValue(
        `${fieldItemPrefix}.internalSources.${index}.transportMode`,
        ""
      );
    });
    internalSourcesAdditions?.forEach((_, index) => {
      removeInternalAdditions(index);
    });
    setIsSupplyEdit(false);
  };

  const cancelExternalEdit = () => {
    currentStageData.input?.externalSources?.forEach((_, index) => {
      fc.setValue(
        `${fieldItemPrefix}.externalSources.${index}.totalWeight`,
        ""
      );
      fc.setValue(
        `${fieldItemPrefix}.externalSources.${index}.distanceTraveled`,
        ""
      );
      fc.setValue(
        `${fieldItemPrefix}.externalSources.${index}.transportMode`,
        ""
      );
    });
    externalSourcesAdditions?.forEach((_, index) => {
      removeExternalAdditions(index);
    });
    setIsExternalEdit(false);
  };

  return (
    <div>
      {openDescriptionDialog && (
        <DefinitionsDialog
          open
          prefix="BASELINE.FORM.INPUT.SHRIMP.SOURCE"
          itemHeader="BASELINE.FORM.INPUT.SHRIMP.SOURCE"
          items={lookupValues.shrimpInputSourceDefinitions[StageType.Nursery]}
          handleClose={() => setOpenDescriptionDialog(false)}
        />
      )}
      <DialogContainer
        formVisible={formVisible}
        handleClose={handleResetClick}
        iconCode="general/check-heart"
        variant="wide"
        formTitle={intl.formatMessage({
          id: "BASELINE.FORM.POST_LARVAE.SHRIMP.POST_LARVAE",
        })}
        introText={intl.formatMessage({
          id: "BASELINE.FORM.JUVENILES.SHRIMP.DESCRIPTION",
        })}
      >
        <DsmButton
          variant="text"
          className="mb-5"
          onClick={() => setOpenDescriptionDialog(true)}
        >
          <DsmIcon slot="before" name="general/eye" />
          {intl.formatMessage({ id: "DATABASE_FOUNDATION_DEFINITIONS" })}
        </DsmButton>
        <ShrimpInputDurationComponent
          formType={formType}
          fieldItemPrefix={fieldItemPrefix}
          currentStageData={currentStageData}
        />
        <ShrimpInputPondComponent
          formType={formType}
          fieldItemPrefix={fieldItemPrefix}
          currentStageData={currentStageData}
          stageType={StageType.Nursery}
        />
        <p className={classes.headingTitleVariant}>
          {intl.formatMessage({ id: "BASELINE.FORM.INPUT.SHRIMP.STOCK" })}
        </p>
        <ShrimpInputStockComponent
          formType={formType}
          fieldItemPrefix={fieldItemPrefix}
          currentStageData={currentStageData}
          stageType={StageType.Nursery}
        />
        <p className={classes.headingTitleVariant}>
          {intl.formatMessage({ id: "BASELINE.FORM.INPUT.SHRIMP.NEW_ANIMALS" })}
        </p>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <div>
            {currentStageData.input?.internalSources?.map((item, index) => (
              <ShrimpInputSupplyComponent
                key={item.id}
                farms={farms}
                farmId={farmId}
                farmName={farmName}
                stageIndex={stageIndex}
                itemIndex={index}
                productionProcessName={productionProcessName}
                formType={formType}
                isSupplyEdit={isSupplyEdit}
                animalType={animalType}
                stageType={StageType.Nursery}
                baseline={baseline}
              />
            ))}
            {internalSourcesAdditions &&
              internalSourcesAdditions?.map((item, index) => (
                <SGrowingSupplyForm
                  farms={farms}
                  farmId={farmId}
                  farmName={farmName}
                  productionProcessName={productionProcessName}
                  key={item.keyId}
                  stageIndex={stageIndex}
                  itemIndex={index}
                  allowedStagesForFarm={allowedStages}
                  formType={formType}
                  removeHandler={removeInternalAdditions}
                  sourceSelected={isSupplyEdit}
                  animalType={animalType}
                  stageType={StageType.Nursery}
                  checkboxUpdateHandler={() => { }}
                  isIntervention
                />
              ))}
            <DsmGrid className={classes.dsmGridOneColumnNoGap}>
              {isSupplyEdit && (
                <DsmButton
                  variant="text"
                  onClick={addInternalSource}
                  disabled={formType === FormType.View}
                  style={{ marginTop: "10px" }}
                >
                  <DsmIcon slot="before" name="general/plus-circle" />
                  {intl.formatMessage({
                    id: "BASELINE.FORM.JUVENILES.SHRIMP.ADD_INTERNAL_SOURCE",
                  })}
                </DsmButton>
              )}
              {!isSupplyEdit ? (
                <DsmButton
                  variant="text"
                  onClick={() => setIsSupplyEdit(true)}
                  disabled={formType === "view"}
                  style={{ width: "120px", marginTop: "10px" }}
                >
                  <DsmIcon slot="before" name="general/edit-02" />
                  <u>
                    {intl.formatMessage({
                      id: "INTERVENTION.FORM.EDIT_INPUT",
                    })}
                  </u>
                </DsmButton>
              ) : (
                <DsmButton
                  variant="text"
                  destructive
                  onClick={() => cancelSupplyEdit()}
                  disabled={formType === "view"}
                  style={{ width: "160px", marginTop: "10px" }}
                >
                  <DsmIcon slot="before" name="general/minus-circle" />
                  <u>
                    {intl.formatMessage({
                      id: "INTERVENTION.FORM.CANCEL",
                    })}
                  </u>
                </DsmButton>
              )}
            </DsmGrid>
          </div>
          <div>
            {currentStageData.input?.externalSources?.map((item, index) => (
              <ShrimpInputExternalComponent
                key={item.id}
                stageIndex={stageIndex}
                itemIndex={index}
                formType={formType}
                isExternalEdit={isExternalEdit}
                stageType={StageType.Nursery}
                baseline={baseline}
              />
            ))}
            {externalSourcesAdditions &&
              externalSourcesAdditions?.map((item, index) => (
                <SGrowingExternalSourceForm
                  key={item.keyId}
                  stageIndex={stageIndex}
                  itemIndex={index}
                  formType={formType}
                  removeHandler={removeExternalAdditions}
                  stageType={StageType.Nursery}
                  sourceSelected={isExternalEdit}
                  isIntervention
                />
              ))}
            <DsmGrid className={classes.dsmGridOneColumnNoGap}>
              {isExternalEdit && (
                <DsmButton
                  variant="text"
                  onClick={addExternalSource}
                  disabled={formType === FormType.View}
                  style={{ marginTop: "10px" }}
                >
                  <DsmIcon slot="before" name="general/plus-circle" />
                  {intl.formatMessage({
                    id: "BASELINE.FORM.JUVENILES.SHRIMP.ADD_EXTERNAL_SOURCE",
                  })}
                </DsmButton>
              )}
              {!isExternalEdit ? (
                <DsmButton
                  variant="text"
                  onClick={() => setIsExternalEdit(true)}
                  disabled={formType === "view"}
                  style={{ width: "120px", marginTop: "10px" }}
                >
                  <DsmIcon slot="before" name="general/edit-02" />
                  <u>
                    {intl.formatMessage({
                      id: "INTERVENTION.FORM.EDIT_INPUT",
                    })}
                  </u>
                </DsmButton>
              ) : (
                <DsmButton
                  variant="text"
                  destructive
                  onClick={() => cancelExternalEdit()}
                  disabled={formType === "view"}
                  style={{ width: "160px", marginTop: "10px" }}
                >
                  <DsmIcon slot="before" name="general/minus-circle" />
                  <u>
                    {intl.formatMessage({
                      id: "INTERVENTION.FORM.CANCEL",
                    })}
                  </u>
                </DsmButton>
              )}
            </DsmGrid>
          </div>
        </DsmGrid>
        <DsmButtonControlGroup
          cancelHandler={handleResetClick}
          saveHandler={() => {
            fc.trigger(fieldItemPrefix);
            handleCancel();
          }}
          saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
        />
      </DialogContainer>
    </div>
  );
};

export default ShrimpInputNurseryDialog;
