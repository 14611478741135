import { SetStateAction, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { InterventionStageProps, MasterDataEnumType } from '../CommonDataParts/InterventionTypes';
import { AnimalType, StageType } from '../../../../../../graphql/types';
import { DairyV2Farms, ListEntry, ShrimpFarms } from '../../common';
import StageButtonBox from '../../StageButtonBox_v2';
import { DairyV2Baseline } from '../../../../models/Baseline/DairyV2Baseline';
import { Origin } from '../types';

interface DairyV2InterventionStagesProps extends Omit<InterventionStageProps, 'manureManagementSystems'> {
    farms: DairyV2Farms[];
    farmId: string;
    farmName: string;
    productionProcessName: string;
    baseline: DairyV2Baseline;
    origins: Origin[];
    animalType: AnimalType.DairyV2;
    masterDataEnums: MasterDataEnumType | null;
    stageType?: StageType;
    calculateCompleteness: (validationFunction: any, prefix: string) => number;
}

interface CloseDialogData {
    type: string;
    setFunc: React.Dispatch<React.SetStateAction<number>>;
    prefix: 'input' | 'output' | 'feed' | 'housing' | 'emissions';
}

interface InitialCalcuationData {
    prefix: 'input' | 'output' | 'feed' | 'housing' | 'emissions';
}

const DairyV2InterventionStages = ({
    farms,
    farmName,
    farmId,
    productionProcessName,
    stageIndex,
    baseline,
    compoundFeeds,
    singleIngredients,
    origins,
    formType,
    stageType = StageType.DairyCow,
    animalType,
    calculateCompleteness,
}: DairyV2InterventionStagesProps) => {

    const fc = useFormContext();
    const [activeDialog, setActiveDialog] = useState<string>('');

    const showDialog = (dialog: SetStateAction<string>) => {
        setActiveDialog(dialog);
    };

    const stagePrefix = `stages.${stageIndex}`;
    const [inputDairyCowCompleteness, setInputDairyCowCompleteness] = useState(0);
    const [inputContinuousGrowingCompleteness, setInputContinuousGrowingCompleteness] = useState(0);
    const [feedDairyCowCompleteness, setFeedDairyCowCompleteness] = useState(0);
    const [feedContinuousGrowingCompleteness, setFeedContinuousGrowingCompleteness] = useState(0);
    const [housingAndManureCompletness, setHousingAndManureCompletness] = useState(0);
    const [outputDairyCowCompleteness, setOutputDairyCowCompleteness] = useState(0);
    const [outputContinuousGrowingCompleteness, setOutputContinuousGrowingCompleteness] = useState(0);
    const [emissionCompleteness, setEmissionCompleteness] = useState(0);

    return (
        <>
            <input
                ref={fc.register()}
                type="hidden"
                name={`${stagePrefix}.id`}
                value={`${baseline?.stages?.[stageIndex]?.id}`}
            />
            <input
                ref={fc.register()}
                type="hidden"
                name={`${stagePrefix}.name`}
                value={`${baseline?.stages?.[stageIndex]?.name}`}
            />
            <input
                ref={fc.register()}
                type="hidden"
                name={`${stagePrefix}.type`}
                value={stageType}
            />
            <input
                ref={fc.register()}
                type="hidden"
                name={`${stagePrefix}.index`}
                value={stageIndex}
            />

            {stageType === StageType.DairyCow && activeDialog === 'input' && (
                <></>
            )}
            {stageType === StageType.ContinuousGrowing && activeDialog === 'input' && (
                <></>
            )}
            {stageType === StageType.DairyCow && activeDialog === 'feed' && (
                <></>
            )}
            {stageType === StageType.ContinuousGrowing && activeDialog === 'feed' && (
                <></>
            )}
            {activeDialog === 'housing' && (
                <></>
            )}
            {stageType === StageType.DairyCow && activeDialog === 'output' && (
                <></>
            )}
            {stageType === StageType.ContinuousGrowing && activeDialog === 'output' && (
                <></>
            )}
            {activeDialog === 'emissions' && (
                <></>
            )}

            <Box
                style={{ display: "flex", width: "100%" }}
                flexDirection="row"
                justifyContent="space-between"
            >
                <StageButtonBox
                    titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"
                    iconCode="general/check-heart"
                    description="SUSTELL.STAGE.INPUT_DATA"
                    error={
                        fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input
                    }
                    handleOpen={() => showDialog('input')}
                />
                <StageButtonBox
                    titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
                    iconCode="shapes/cube-02"
                    description="SUSTELL.STAGE.INPUT_DATA"
                    error={
                        fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.feed
                    }
                    handleOpen={() => showDialog('feed')}
                />
                <StageButtonBox
                    titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
                    iconCode="general/building-06"
                    description="SUSTELL.STAGE.INPUT_DATA"
                    error={
                        fc.errors?.stages &&
                        fc.errors?.stages[stageIndex]?.stageData?.housing
                    }
                    handleOpen={() => showDialog('housing')}
                />
                <StageButtonBox
                    titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
                    iconCode="arrows/arrow-circle-broken-right"
                    description="SUSTELL.STAGE.INPUT_DATA"
                    error={
                        fc.errors?.stages &&
                        fc.errors?.stages[stageIndex]?.stageData?.output
                    }
                    handleOpen={() => showDialog('output')}
                />
                <StageButtonBox
                    titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
                    iconCode="maps-travel/globe-04"
                    description="SUSTELL.STAGE.INPUT_DATA"
                    handleOpen={() => showDialog('emissions')}
                    error={
                        fc.errors?.stages &&
                        fc.errors?.stages[stageIndex]?.stageData?.emissions
                    }
                />
            </Box>
        </>
    );
};

export default DairyV2InterventionStages;
