import { ManureForm } from "../../../../../graphql/generated/blonk/pigs";
import { UserProfilePrefs } from "../../../Helpers/UserProfilePrefs";
import { floatValueInUserUnits } from "../../Baseline/mapper/inCommonMapperSustellV2";
import {
  mapCommonInterventionDataIn,
  mapResourceUse,
} from "./inCommonMapperSustellV2";
import { defaultUnitsV2 as defaultMetric } from "../../../../sustell_15/utils/unit-utils";
import { getBaseTypeForMonthEndingMMS } from "../../../../sustell_15/utils/string-utils";
import { StageType } from "../../../../../graphql/types";

const mapOutputForOneStage = (stageOutputDataObj, outputFormDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputFormData = outputFormDataObj;
  if (stageOutputDataObj) {
    // weight of pigs - conversion to default units
    if (stageOutputDataObj.averageWeightPigs) {
      outputFormData.averageWeightPigs = floatValueInUserUnits(
        stageOutputDataObj.averageWeightPigs.value,
        defaultMetric.unitBarnOutputMass,
        userUOM.unitBarnOutputMass
      );

      outputFormData.averageWeightPigs_changeMetric =
        stageOutputDataObj.averageWeightPigs.change_type;
    }

    // weight of piglets - conversion to default units
    if (stageOutputDataObj.averageWeightPiglets) {
      outputFormData.averageWeightPiglets = floatValueInUserUnits(
        stageOutputDataObj.averageWeightPiglets.value,
        defaultMetric.unitBarnOutputMass,
        userUOM.unitBarnOutputMass
      );
      outputFormData.averageWeightPiglets_changeMetric =
        stageOutputDataObj.averageWeightPiglets.change_type;
    }

     // average age of piglets
     if (
      stageOutputDataObj.averageAgePiglets ||
      stageOutputDataObj.averageAgePiglets === 0
    ) {
      outputFormData.averageAgePiglets = stageOutputDataObj.averageAgePiglets.value;
      outputFormData.averageAgePiglets_changeMetric =
        stageOutputDataObj.averageAgePiglets.change_type;
    }

    // number of pigs
    if (
      stageOutputDataObj.mortalityPigs ||
      stageOutputDataObj.mortalityPigs === 0
    ) {
      outputFormData.mortalityPigs = stageOutputDataObj.mortalityPigs.value;
      outputFormData.mortalityPigs_changeMetric =
        stageOutputDataObj.mortalityPigs.change_type;
    }

    // number of piglets
    if (
      stageOutputDataObj.mortalityPiglets ||
      stageOutputDataObj.mortalityPiglets === 0
    ) {
      outputFormData.mortalityPiglets =
        stageOutputDataObj.mortalityPiglets.value;
      outputFormData.mortalityPiglets_changeMetric =
        stageOutputDataObj.mortalityPiglets.change_type;
    }

    // number of pigs
    if (stageOutputDataObj.pigsToStage) {
      outputFormData.pigsToStage = stageOutputDataObj.pigsToStage.value;
      outputFormData.pigsToStage_changeMetric =
        stageOutputDataObj.pigsToStage.change_type;
    }

    // number of piglets
    if (stageOutputDataObj.pigletsToStage) {
      outputFormData.pigletsToStage = stageOutputDataObj.pigletsToStage.value;
      outputFormData.pigletsToStage_changeMetric =
        stageOutputDataObj.pigletsToStage.change_type;
    }

    // number of pigs
    if (stageOutputDataObj.soldPigs) {
      outputFormData.soldPigs = stageOutputDataObj.soldPigs.value;
      outputFormData.soldPigs_changeMetric =
        stageOutputDataObj.soldPigs.change_type;
    }

    // number of piglets
    if (stageOutputDataObj.soldPiglets) {
      outputFormData.soldPiglets = stageOutputDataObj.soldPiglets.value;
      outputFormData.soldPiglets_changeMetric =
        stageOutputDataObj.soldPiglets.change_type;
    }

    // number of days
    if (stageOutputDataObj.averageAgePigs) {
      outputFormData.averageAgePigs = stageOutputDataObj.averageAgePigs.value;
      outputFormData.averageAgePigs_changeMetric =
        stageOutputDataObj.averageAgePigs.change_type;
    }

    // number of days
    if (stageOutputDataObj.ageAtMortalityPigs) {
      outputFormData.ageAtMortalityPigs =
        stageOutputDataObj.ageAtMortalityPigs.value;
      outputFormData.ageAtMortalityPigs_changeMetric =
        stageOutputDataObj.ageAtMortalityPigs.change_type;
    }

    // price in EUR - possible future conversion needed
    if (stageOutputDataObj.priceSoldPigs) {
      outputFormData.priceSoldPigs = stageOutputDataObj.priceSoldPigs.value;
      outputFormData.priceSoldPigs_changeMetric =
        stageOutputDataObj.priceSoldPigs.change_type;
    }

    // price in EUR - possible future conversion needed
    if (stageOutputDataObj.priceSoldPiglets) {
      outputFormData.priceSoldPiglets =
        stageOutputDataObj.priceSoldPiglets.value;
      outputFormData.priceSoldPiglets_changeMetric =
        stageOutputDataObj.priceSoldPiglets.change_type;
    }

    // weight of pigs - conversion to default units
    if (stageOutputDataObj.weightAtMortalityPigs) {
      outputFormData.weightAtMortalityPigs = floatValueInUserUnits(
        stageOutputDataObj.weightAtMortalityPigs.value,
        defaultMetric.unitBarnOutputMass,
        userUOM.unitBarnOutputMass
      );
      outputFormData.weightAtMortalityPigs_changeMetric =
        stageOutputDataObj.weightAtMortalityPigs.change_type;
    }

    // weight of piglets - conversion to default units
    if (stageOutputDataObj.weightAtMortalityPiglets) {
      outputFormData.weightAtMortalityPiglets = floatValueInUserUnits(
        stageOutputDataObj.weightAtMortalityPiglets.value,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
      outputFormData.weightAtMortalityPiglets_changeMetric =
        stageOutputDataObj.weightAtMortalityPiglets.change_type;
    }
  }
};

const mapInputForOneStage = (
  stageDataObj,
  formStageDataInputPart,
  stageType
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formInputData = formStageDataInputPart;

  if (stageType === "FATTENING") {
    if (stageDataObj?.pigsPresentAtStart) {
      // field is only present if stockPresent was checked
      formInputData.stockPresent = true;
      formInputData.optionalInput = {};
      formInputData.optionalInput.pigsPresentAtStart =
        stageDataObj.pigsPresentAtStart.value;
      formInputData.optionalInput.pigsPresentAtStart_changeMetric =
        stageDataObj.pigsPresentAtStart.change_type;
    }
    if (formInputData.stockPresent) {
      // console.log("There are optional fields");
      if (stageDataObj?.pigsPresentAtEnd) {
        formInputData.optionalInput.pigsPresentAtEnd =
          stageDataObj.pigsPresentAtEnd.value;
        formInputData.optionalInput.pigsPresentAtEnd_changeMetric =
          stageDataObj.pigsPresentAtEnd.change_type;
      }

      if (stageDataObj?.averageWeightPigsStart) {
        formInputData.optionalInput.averageWeightPigsStart =
          floatValueInUserUnits(
            stageDataObj.averageWeightPigsStart.value,
            defaultMetric.unitBarnOutputMass,
            userUOM.unitBarnOutputMass
          );
        formInputData.optionalInput.averageWeightPigsStart_changeMetric =
          stageDataObj.averageWeightPigsStart.change_type;
      }

      if (stageDataObj?.averageWeightPigsEnd) {
        formInputData.optionalInput.averageWeightPigsEnd =
          floatValueInUserUnits(
            stageDataObj.averageWeightPigsEnd.value,
            defaultMetric.unitBarnOutputMass,
            userUOM.unitBarnOutputMass
          );
        formInputData.optionalInput.averageWeightPigsEnd_changeMetric =
          stageDataObj.averageWeightPigsEnd.change_type;
      }

      if (stageDataObj?.averageAgePigsStart) {
        formInputData.optionalInput.averageAgePigsStart =
          stageDataObj.averageAgePigsStart.value;
        formInputData.optionalInput.averageAgePigsStart_changeMetric =
          stageDataObj.averageAgePigsStart.change_type;
      }

      if (stageDataObj?.averageAgePigsEnd) {
        formInputData.optionalInput.averageAgePigsEnd =
          stageDataObj.averageAgePigsEnd.value;
        formInputData.optionalInput.averageAgePigsEnd_changeMetric =
          stageDataObj.averageAgePigsEnd.change_type;
      }

      if (stageDataObj?.averageAgePigsEnd) {
        formInputData.optionalInput.averageAgePigsEnd =
          stageDataObj.averageAgePigsEnd.value;
        formInputData.optionalInput.averageAgePigsEnd_changeMetric =
          stageDataObj.averageAgePigsEnd.change_type;
      }

      if (stageDataObj?.pigsPriceAtStart) {
        formInputData.optionalInput.pigsPriceAtStart =
          stageDataObj.pigsPriceAtStart.value;
        formInputData.optionalInput.pigsPriceAtStart_changeMetric =
          stageDataObj.pigsPriceAtStart.change_type;
      }
    }
  }
  if (stageType === "BREEDING") {
    if (stageDataObj?.pigsPresentAtStart) {
      formInputData.pigsPresentAtStart = stageDataObj.pigsPresentAtStart.value;
      formInputData.pigsPresentAtStart_changeMetric =
        stageDataObj.pigsPresentAtStart.change_type;
    }
    if (stageDataObj?.pigsPresentAtEnd) {
      formInputData.pigsPresentAtEnd = stageDataObj.pigsPresentAtEnd.value;
      formInputData.pigsPresentAtEnd_changeMetric =
        stageDataObj.pigsPresentAtEnd.change_type;
    }
  }

  formInputData.internalSources = [];
  formInputData.externalSources = [];
  if (
    stageDataObj.animalsInput?.incomings &&
    stageDataObj.animalsInput.incomings.length > 0
  ) {
    stageDataObj.animalsInput.incomings.forEach((item) => {
      if (item.origin === "PRODUCTION_SYSTEM") {
        const newSource = {
          id: item.id,
          numberPigs: item.numberPigs?.value,
          numberPigs_changeMetric: item.numberPigs?.change_type,
          originStageId: item.originStageId,
        };
        if (item.averageAgePigs) {
          newSource.averageAgeOfPigs = item.averageAgePigs?.value;
          newSource.averageAgeOfPigs_changeMetric =
            item.averageAgePigs?.change_type;
        }
        if (item.averageWeightPigs) {
          newSource.averageWeightOfPigs = item.averageWeightPigs?.value;
          newSource.averageWeightOfPigs_changeMetric =
            item.averageWeightPigs?.change_type;
        }
        if (item.distanceTransport) {
          newSource.distanceTransport = floatValueInUserUnits(
            item.distanceTransport.value,
            defaultMetric.unitTransportDistanceTerrestrial,
            userUOM.unitTransportDistanceTerrestrial
          );
          newSource.distanceTransport_changeMetric =
            item.distanceTransport.change_type;
        }
        formInputData.internalSources.push(newSource);
      }
      if (item.origin === "BACKGROUND_DATA") {
        formInputData.externalSources.push({
          id: item.id,
          numberPigs: item.numberPigs?.value,
          numberPigs_changeMetric: item.numberPigs?.change_type,

          averageWeightOfPigs: floatValueInUserUnits(
            item.averageWeightPigs?.value,
            defaultMetric.unitBarnOutputMass,
            userUOM.unitBarnOutputMass
          ),
          averageWeightOfPigs_changeMetric: item.averageWeightPigs?.change_type,

          averageAgeOfPigs: item.averageAgePigs?.value,
          averageAgeOfPigs_changeMetric: item.averageAgePigs?.change_type,

          distanceTransport: floatValueInUserUnits(
            item.distanceTransport?.value,
            defaultMetric.unitTransportDistanceTerrestrial,
            userUOM.unitTransportDistanceTerrestrial
          ),
          distanceTransport_changeMetric: item.distanceTransport?.change_type,
        });
      }
    });
  }

  formInputData.internalSourcesAdditions = [];
  formInputData.externalSourcesAdditions = [];
  const { originAnimalType } = formInputData;
  if (
    stageDataObj.animalsInput?.incomingsAdditions &&
    stageDataObj.animalsInput.incomingsAdditions.length > 0
  ) {
    stageDataObj.animalsInput.incomingsAdditions.forEach((item) => {
      if (item.origin === "PRODUCTION_SYSTEM") {
        const newSource = {
          id: item.id,
          numberPigs: item.numberPigs,
          originStageId: item.originStageId,
          originAnimalType,
          farmId: item.originFarmId,
          farmName: item.originFarmName,
          stageName: item.originStageName,
          stageType: item.stageType,
          averageWeightPiglets: item.averageWeightPiglets,
          averageAgePiglets: item.averageAgePiglets,
          averageWeightPigs: item.averageWeightPigs,
          averageAgePigs: item.averageAgePigs,
        };
        if (item.distanceTransport) {
          newSource.distanceTransport = floatValueInUserUnits(
            item.distanceTransport,
            defaultMetric.unitTransportDistanceTerrestrial,
            userUOM.unitTransportDistanceTerrestrial
          );
        }
        formInputData.internalSourcesAdditions.push(newSource);
      }
      if (item.origin === "BACKGROUND_DATA") {
        formInputData.externalSourcesAdditions.push({
          id: item.id,
          numberPigs: item.numberPigs,
          averageWeightOfPigs: floatValueInUserUnits(
            item.averageWeightPigs,
            defaultMetric.unitBarnOutputMass,
            userUOM.unitBarnOutputMass
          ),
          averageAgeOfPigs: item.averageAgePigs,
          distanceTransport: floatValueInUserUnits(
            item.distanceTransport,
            defaultMetric.unitTransportDistanceTerrestrial,
            userUOM.unitTransportDistanceTerrestrial
          ),
        });
      }
    });
  }
};

const mapMaterialsForOneStage = (stageMaterialsDataObj, formHousingDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formHousingData = formHousingDataObj;

  formHousingData.materials = [];
  if (stageMaterialsDataObj) {
    Object.keys(stageMaterialsDataObj).forEach((key) => {
      let value = "";
      if (stageMaterialsDataObj[key])
        value = floatValueInUserUnits(
          Number(stageMaterialsDataObj[key].value),
          defaultMetric.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        );
      // keys are now ending with "Use"
      let trimmedKey = key;
      const suffixIndex = key.lastIndexOf("Use");
      if (suffixIndex > -1) trimmedKey = key.substring(0, suffixIndex);

      const materialType = trimmedKey
        .replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`)
        .toUpperCase();

      const item = {
        materialType,
        materialAmount: value,
        materialAmount_changeMetric: stageMaterialsDataObj[key].change_type,
      };
      formHousingData.materials.push(item);
    });
  }
};

const mapMaterialsForOneStageAdditions = (
  stageMaterialsDataObj,
  formHousingDataObj
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formHousingData = formHousingDataObj;

  formHousingData.materialsAdditions = [];
  if (stageMaterialsDataObj) {
    Object.keys(stageMaterialsDataObj).forEach((key) => {
      let value = "";
      if (stageMaterialsDataObj[key])
        value = floatValueInUserUnits(
          Number(stageMaterialsDataObj[key]),
          defaultMetric.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        );
      // keys are now ending with "Use"
      let trimmedKey = key;
      const suffixIndex = key.lastIndexOf("Use");
      if (suffixIndex > -1) trimmedKey = key.substring(0, suffixIndex);

      const materialType = trimmedKey
        .replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`)
        .toUpperCase();

      const item = {
        materialType,
        materialAmount: value,
      };
      formHousingData.materialsAdditions.push(item);
    });
  }
};

const mapBeddingForOneStage = (stageBeddingDataObj, formHousingDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formHousingData = formHousingDataObj;
  formHousingData.beddingSystems = [];
  if (stageBeddingDataObj) {
    Object.keys(stageBeddingDataObj).forEach((key) => {
      let value = "";
      if (stageBeddingDataObj[key])
        value = floatValueInUserUnits(
          Number(stageBeddingDataObj[key].value),
          defaultMetric.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        );
      // keys are now ending with "Use"
      let trimmedKey = key;
      const suffixIndex = key.lastIndexOf("Use");
      if (suffixIndex > -1) trimmedKey = key.substring(0, suffixIndex);
      // console.log('key', key, trimmedKey);
      const beddingType = trimmedKey
        .replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`)
        .toUpperCase();

      const item = {
        beddingType,
        beddingAmount: value,
        beddingAmount_changeMetric: stageBeddingDataObj[key].change_type,
      };
      formHousingData.beddingSystems.push(item);
    });
  }
};

const mapBeddingForOneStageAdditions = (
  stageBeddingDataObj,
  formHousingDataObj
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formHousingData = formHousingDataObj;

  formHousingData.beddingSystemsAdditions = [];
  if (stageBeddingDataObj) {
    Object.keys(stageBeddingDataObj).forEach((key) => {
      let value = "";
      if (stageBeddingDataObj[key])
        value = floatValueInUserUnits(
          Number(stageBeddingDataObj[key]),
          defaultMetric.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        );
      // keys are now ending with "Use"
      let trimmedKey = key;
      const suffixIndex = key.lastIndexOf("Use");
      if (suffixIndex > -1) trimmedKey = key.substring(0, suffixIndex);
      // console.log('key', key, trimmedKey);
      const beddingType = trimmedKey
        .replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`)
        .toUpperCase();

      const item = {
        beddingType,
        beddingAmount: value,
      };
      formHousingData.beddingSystemsAdditions.push(item);
    });
  }
};

const mapHousingForOneStage = (stageHousingData, formHousingDataObj) => {
  // const userPrefs = UserProfilePrefs.getInstance();
  // const userUOM = userPrefs.getUserUnitPrefs();
  const housingFormData = formHousingDataObj;

  housingFormData.surfaceType = stageHousingData?.surfaceType;
  // housingFormData.manureForm = stageHousingData?.manureForm || '';

  housingFormData.Nreplacement = stageHousingData?.Nreplacement?.value;
  if (housingFormData.Nreplacement === null) housingFormData.Nreplacement = "";
  housingFormData.Nreplacement_changeMetric =
    stageHousingData?.Nreplacement?.change_type;

  housingFormData.Preplacement = stageHousingData?.Preplacement?.value;
  if (housingFormData.Preplacement === null) housingFormData.Preplacement = "";
  housingFormData.Preplacement_changeMetric =
    stageHousingData?.Preplacement?.change_type;

  housingFormData.manureSystems = [];
  housingFormData.manureSystemsAdditions = [];

  stageHousingData?.solidManureManagementSystem?.forEach((item) => {
    const baseName = item.mmsType
      ? getBaseTypeForMonthEndingMMS(item.mmsType)
      : undefined;
    const newItem = {
      id: item.id,
      localManureForm: ManureForm.Solid,
      mmsType: baseName,
      share: item.share?.value,
      share_changeMetric: item.share?.change_type,
    };

    // set if defined, otherwise use baseline value
    if (
      baseName !== undefined &&
      item.mmsType !== undefined &&
      baseName !== item.mmsType
    )
      newItem.mmsHoldingDuration = item.mmsType;

    housingFormData.manureSystems.push(newItem);
  });

  stageHousingData?.liquidSlurryManureManagementSystem?.forEach((item) => {
    const baseName = item.mmsType
      ? getBaseTypeForMonthEndingMMS(item.mmsType)
      : undefined;

    const newItem = {
      id: item.id,
      localManureForm: ManureForm.LiquidSlurry,
      mmsType: baseName,
      share: item.share?.value,
      share_changeMetric: item.share?.change_type,
    };

    // set if defined, otherwise use baseline value
    if (
      baseName !== undefined &&
      item.mmsType !== undefined &&
      baseName !== item.mmsType
    )
      newItem.mmsHoldingDuration = item.mmsType;

    housingFormData.manureSystems.push(newItem);
  });

  const totalNumOfSolids =
    (stageHousingData?.solidManureManagementSystemAdditions?.length || 0) +
    (stageHousingData?.solidManureManagementSystem?.length || 0);
  stageHousingData?.solidManureManagementSystemAdditions?.forEach((item) => {
    const baseName = getBaseTypeForMonthEndingMMS(item.mmsType);
    const newItem = {
      id: item.id,
      localManureForm: ManureForm.Solid,
      mmsType: baseName,
      mmsHoldingDuration: baseName === item.mmsType ? "" : item.mmsType,
      share: totalNumOfSolids === 1 ? 100 : item.share,
    };
    housingFormData.manureSystemsAdditions.push(newItem);
  });

  const totalNumOfLiquidSlurry =
    (stageHousingData?.liquidSlurryManureManagementSystemAdditions?.length ||
      0) + (stageHousingData?.liquidSlurryManureManagementSystem?.length || 0);
  stageHousingData?.liquidSlurryManureManagementSystemAdditions?.forEach(
    (item) => {
      const baseName = getBaseTypeForMonthEndingMMS(item.mmsType);
      const newItem = {
        id: item.id,
        localManureForm: ManureForm.LiquidSlurry,
        mmsType: baseName,
        mmsHoldingDuration: baseName === item.mmsType ? "" : item.mmsType,
        share: totalNumOfLiquidSlurry === 1 ? 100 : item.share,
      };
      housingFormData.manureSystemsAdditions.push(newItem);
    }
  );

  if (
    (stageHousingData.solidManureManagementSystem?.length ||
      stageHousingData.solidManureManagementSystemAdditions?.length) &&
    (stageHousingData.liquidSlurryManureManagementSystem?.length ||
      stageHousingData.solidManureManagementSystemAdditions?.length)
  )
    housingFormData.manureForm = ManureForm.SolidAndLiquidSlurry;
  else if (
    stageHousingData.solidManureManagementSystem?.length ||
    stageHousingData.solidManureManagementSystemAdditions?.length
  )
    housingFormData.manureForm = ManureForm.Solid;
  else if (
    stageHousingData.liquidSlurryManureManagementSystem ||
    stageHousingData.solidManureManagementSystemAdditions?.length
  )
    housingFormData.manureForm = ManureForm.LiquidSlurry;
};

const mapPigInterventionDataInSustell = (inDataObj) => {
  const inData = JSON.parse(JSON.stringify(inDataObj));
  // convert to object if needed
  inData.stages.forEach((stage) => {
    if (stage.stageData && typeof stage.stageData === "string") {
      // eslint-disable-next-line no-param-reassign
      stage.stageData = JSON.parse(stage.stageData) || {};
    }
  });
  const formData = mapCommonInterventionDataIn(inData);

  if (inData.resourceUse)
    mapResourceUse(inData.resourceUse, formData.resourceUse);

  inData.stages.forEach((stage, index) => {
    if (stage.type !== StageType.Processing) {
      const stageFormData = formData.stages[index]?.stageData;
      // const stageData = JSON.parse(stage.stageData) || {};
      const stageData = stage.stageData || {};

      stageFormData.input.originAnimalType =
        stageData.animalsInput?.originAnimalType;

      if (stageFormData && stage.type !== StageType.Processing) {
        mapInputForOneStage(stageData, stageFormData.input, stage.type);
        if (stageData.animalsOutput)
          mapOutputForOneStage(stageData.animalsOutput, stageFormData.output);

        if (stageData.housing)
          mapHousingForOneStage(stageData.housing, stageFormData.housing);

        if (stageData.beddingUse)
          mapBeddingForOneStage(stageData.beddingUse, stageFormData.housing);
        if (stageData.beddingUseAdditions)
          mapBeddingForOneStageAdditions(
            stageData.beddingUseAdditions,
            stageFormData.housing
          );

        if (stageData.materialUse)
          mapMaterialsForOneStage(stageData.materialUse, stageFormData.housing);
        if (stageData.materialUseAdditions)
          mapMaterialsForOneStageAdditions(
            stageData.materialUseAdditions,
            stageFormData.housing
          );
      }
    }
  });

  return formData;
};

export default mapPigInterventionDataInSustell;
