import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { Maybe } from 'graphql/jsutils/Maybe';

import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { FormType, ListEntry } from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { InputAnimalOrigin } from '../../../../../../graphql/generated/blonk/pigs';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { ShrimpBaseline } from '../../../../models/Baseline/ShrimpBaseline';

interface ShrimpChemicalFormProps {
  stageIndex?: number | undefined;
  itemIndex?: number | undefined;
  formType: FormType;
  editEnabled?: boolean;
  removeHandler?: Maybe<Function>;
  required?: boolean;
  options: ListEntry[];
  baseline: ShrimpBaseline;
}

const ShrimpChemicalForm: FC<ShrimpChemicalFormProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType,
  removeHandler,
  required = false,
  editEnabled = false,
  options,
  baseline
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;

  const formContext = useFormContext();
  const propName = 'chemicalTypes';

  const fieldItemPrefix = `stages.${stageIndex}.stageData.operations.${propName}.${itemIndex}`;
  const chemicalsData = baseline.stages[stageIndex].stageData.operations?.chemicalTypes[itemIndex];

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.type`}
        value={chemicalsData?.type}
      />
      <DsmGrid className={classes.dsmGridTwoColumn}>
        {!editEnabled && !formContext.getValues(`${fieldItemPrefix}.amount`) ? (
          <>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalAmount`}
              label={chemicalsData?.type as unknown as string}
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICAL_TYPE_AMOUNT.TOOLTIP',
              })}
              adornment={intl.formatMessage({
                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICAL_TYPE_AMOUNT.PLACEHOLDER',
              })}
              type="number"
              disabled
              required={required}
              defaultValue={chemicalsData?.amount}
            />
          </>
        ) : (
          <>
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.amount`}
              label={chemicalsData?.type as unknown as string}
              type="number"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.OPERATIONS.SHRIMP.CHEMICAL_TYPE_AMOUNT.TOOLTIP',
              })}
              metricUnit=''
              baseline={chemicalsData?.amount}
              disabled={formType === FormType.View || !editEnabled}
              displayTypeSelect={editEnabled}
            />

            {formType !== FormType.View && removeHandler && editEnabled && (
              <DsmButton
                variant="text"
                iconOnly
                destructive
                style={{ padding: '0px', marginTop: '24px' }}
                onClick={() => removeHandler(itemIndex)}
              >
                <DsmIcon
                  slot='before'
                  name="general/trash-01"
                />
              </DsmButton>
            )}
          </>
        )}
      </DsmGrid>
    </>
  );
};

export default ShrimpChemicalForm;
