import { v4 as uuidv4 } from "uuid";
import { defaultUnitsV2 } from "../../../../sustell_15/utils/unit-utils";
import { UserProfilePrefs } from "../../../Helpers/UserProfilePrefs";
import {
  createCorePayload,
  mapCompoundFeedForOneStage,
  mapResourceUseSustell,
  mapSingleIngridientsForOneStage,
} from "../mapper/outCommonMapperSustellV2";
import {
  getDefaultTransportUnit,
  getUserTransportUnit,
} from "../../Baseline/mapper/outCommonMapperSustellV2";
import { setInterventionFloatValue } from "./outCommonMapperSustell";
import {
  GrowingInterventionSupply as TempGrowingInterventionSupply,
  GrowingInterventionExternalSource as TempGrowingInterventionExternalSource,
  ShrimpGrowingStageMaterialTypeInput,
  ShrimpOutput,
  ShrimpStageDBModel,
  FeedingProgramDBModel,
  ShrimpStageDataDBModel,
  ShrimpEnergyTypeInput,
} from "../../../../sustell_15/models/TempBackendModels/TempInterventionModels/TempShrimpInterventionDB";
import {
  ShrimpIntervention,
  ShrimpInput as ShrimpInterventionInput,
  GrowingInterventionSupply,
  GrowingInterventionExternalSource,
  ShrimpOperations as ShrimpInterventionOperations,
  FertilizerTypeInput,
  ShrimpMaterialTypeInput,
  ShrimpChemicalTypeInput,
  ShrimpOutputs as ShrimpInterventionOutputs,
  ShrimpOutput as ShrimpInterventionOutput,
  ShrimpInterventionFeed,
  ShrimpStage,
  ShrimpEnergyTypeInput as ShrimpInterventionEnergyTypeInput,
} from "../../../../sustell_15/models/Intervention/ShrimpIntervention";
import { floatValueInDefaultUnit } from "../../Baseline/mapper/floatHelperFunctions";
import {
  AnimalsInputDBModel,
  AnimalsOutputDBModel,
  ChemicalDBModel,
  StageMaterialDBModel,
} from "../../../../sustell_15/models/TempBackendModels/TempShrimpDB";
import {
  InputAnimalOrigin,
  Salinity,
} from "../../../../../graphql/generated/blonk/shrimp";
import {
  GrowingExternalSource,
  GrowingSupply,
} from "../../../../sustell_15/models/Baseline/ShrimpBaseline";
import { EnergyCarrierType } from "../../../../../graphql/generated/blonk/cattle";
import { distance } from "mathjs";

const initStageData = (): ShrimpStageDataDBModel => {
  return {
    input: {
      totalPondArea: {},
      emptyPeriod: {},
      stageDuration: {},
      totalWeightAtStartDate: {},
      totalWeightAtEndDate: {},
      priceAtStartDate: {},
      priceAtEndDate: {},
      internalSources: [],
      externalSources: [],
      internalSourcesAdditions: [],
      externalSourcesAdditions: [],
    },
    feedingProgram: {
      compoundFeedUses: [],
      singleIngredientUses: [],
    },
    operations: {
      resourceUse: {
        electricityUse: {},
        gasUse: {},
        waterUse: {},
        watersalinity: Salinity.BrackishOrFreshwater || Salinity.Saline,
        nitrogenConcentrationIn: {},
        nitrogenConcentrationOut: {},
        waterEnteringPondIn: {},
        waterEnteringPondOut: {},
        selfGeneratedRenewables: [],
        energyTypes: [],
        selfGeneratedRenewablesAdditions: [],
        energyTypesAdditions: [],
      },
      fertilizerTypes: [],
      materialTypes: [],
      chemicalTypes: [],
      iceAmount: {},
      fertilizerTypeAdditions: [],
      materialTypeAdditions: [],
      chemicalTypeAdditions: [],
    },
    output: {
      outputs: [],
      outputAdditions: [],
    },
  };
};

const mapRationForOneStage = (
  feedFormData: ShrimpInterventionFeed,
  stageFeedData: FeedingProgramDBModel
) => {
  mapCompoundFeedForOneStage(feedFormData, stageFeedData);
  mapSingleIngridientsForOneStage(feedFormData, stageFeedData);
};

const mapInputForOneStage = (
  inputBlockData: ShrimpInterventionInput,
  stageGeneralData: ShrimpStageDBModel
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  const { stageData } = stageGeneralData;
  const { input } = stageData;

  if (inputBlockData) {
    setInterventionFloatValue(
      input,
      "totalPondArea",
      inputBlockData,
      "totalPondArea"
    );

    setInterventionFloatValue(
      input,
      "emptyPeriod",
      inputBlockData,
      "emptyPeriod"
    );

    // stock
    if (
      inputBlockData.totalWeightAtStartDate ||
      Number(inputBlockData.totalWeightAtStartDate) === 0
    )
      setInterventionFloatValue(
        input,
        "totalWeightAtStartDate",
        inputBlockData,
        "totalWeightAtStartDate",
        userUOM.unitOutputMass,
        defaultUnitsV2.unitOutputMass,
        outputMassPrecision
      );

    if (
      inputBlockData.totalWeightAtEndDate ||
      Number(inputBlockData.totalWeightAtEndDate) === 0
    )
      setInterventionFloatValue(
        input,
        "totalWeightAtEndDate",
        inputBlockData,
        "totalWeightAtEndDate",
        userUOM.unitOutputMass,
        defaultUnitsV2.unitOutputMass,
        outputMassPrecision
      );

    if (
      inputBlockData.priceAtStartDate ||
      Number(inputBlockData.priceAtStartDate) === 0
    )
      setInterventionFloatValue(
        input,
        "priceAtStartDate",
        inputBlockData,
        "priceAtStartDate"
      );

    if (
      inputBlockData.priceAtEndDate ||
      Number(inputBlockData.priceAtEndDate) === 0
    )
      setInterventionFloatValue(
        input,
        "priceAtEndDate",
        inputBlockData,
        "priceAtEndDate"
      );

    // input - supply
    if (
      inputBlockData.internalSources &&
      Array.isArray(inputBlockData.internalSources)
    ) {
      inputBlockData.internalSources?.forEach(
        (item: GrowingInterventionSupply) => {
          const inputData = {
            id: item.id || uuidv4(),
            comingFromFarm: item.comingFromFarm,
            comingFromStage: item.comingFromStage,
            outputName: item.outputName,
            totalWeight: {},
            distanceTraveled: {},
            transportMode: { value: item.transportMode, change_type: "set" },
          };

          setInterventionFloatValue(
            inputData,
            "totalWeight",
            item,
            "totalWeight",
            userUOM.unitOutputMass,
            defaultUnitsV2.unitOutputMass,
            outputMassPrecision
          );

          setInterventionFloatValue(
            inputData,
            "distanceTraveled",
            item,
            "distanceTraveled"
          );

          input?.internalSources?.push(
            inputData as TempGrowingInterventionSupply
          );
        }
      );
    }

    // input - internal sources additions
    if (
      inputBlockData.internalSourcesAdditions &&
      Array.isArray(inputBlockData.internalSourcesAdditions)
    ) {
      inputBlockData.internalSourcesAdditions?.forEach(
        (item: GrowingSupply) => {
          const newInputInternalSourceData = {
            id: item.id || uuidv4(),
            weight: floatValueInDefaultUnit(
              item.totalWeight,
              userUOM.unitBarnOutputMass,
              defaultUnitsV2.unitBarnOutputMass
            ),
            origin: InputAnimalOrigin.ProductionSystem,
            originStageId: item.comingFromStage,
            originFarmId: item.comingFromFarm,
            transport: [
              {
                mode: item.transportMode,
                distance: floatValueInDefaultUnit(
                  item.distanceTraveled,
                  getUserTransportUnit(item.transportMode),
                  getDefaultTransportUnit(item.transportMode)
                ),
              },
            ],
          };

          input?.internalSourcesAdditions?.push(
            newInputInternalSourceData as AnimalsInputDBModel
          );
        }
      );
    }

    // input - external sources
    if (
      inputBlockData.externalSources &&
      Array.isArray(inputBlockData.externalSources)
    ) {
      inputBlockData.externalSources?.forEach(
        (item: GrowingInterventionExternalSource) => {
          const externalSource = {
            id: item.id || uuidv4(),
            totalWeight: {},
            distanceTraveled: {},
            transportMode: { value: item.transportMode, change_type: "set" },
          };

          setInterventionFloatValue(
            externalSource,
            "totalWeight",
            item,
            "totalWeight",
            userUOM.unitOutputMass,
            defaultUnitsV2.unitOutputMass,
            outputMassPrecision
          );

          setInterventionFloatValue(
            externalSource,
            "distanceTraveled",
            item,
            "distanceTraveled"
          );

          input?.externalSources?.push(
            externalSource as TempGrowingInterventionExternalSource
          );
        }
      );
    }

    // input - external sources additions
    if (
      inputBlockData.externalSourcesAdditions &&
      Array.isArray(inputBlockData.externalSourcesAdditions)
    ) {
      inputBlockData.externalSourcesAdditions?.forEach(
        (item: GrowingExternalSource) => {
          const newInputExternalSourceData = {
            id: item.id || uuidv4(),
            weight: floatValueInDefaultUnit(
              item.totalWeight,
              userUOM.unitBarnOutputMass,
              defaultUnitsV2.unitBarnOutputMass
            ),
            origin: InputAnimalOrigin.BackgroundData,
            transport: [
              {
                mode: item.transportMode,
                distance: floatValueInDefaultUnit(
                  item.distanceTraveled,
                  getUserTransportUnit(item.transportMode),
                  getDefaultTransportUnit(item.transportMode)
                ),
              },
            ],
          };

          input?.externalSourcesAdditions?.push(
            newInputExternalSourceData as AnimalsInputDBModel
          );
        }
      );
    }
  }
};

const mapOperationsForOneStage = (
  operationsBlockData: ShrimpInterventionOperations,
  stageGeneralData: ShrimpStageDBModel
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = stageGeneralData;
  const { operations } = stageData;

  if (operationsBlockData && operations) {
    const { resourceUse, fertilizerTypes, materialTypes, chemicalTypes } =
      operations;

    // resourceUse
    if (
      operationsBlockData.resourceUse?.electricityUse ||
      Number(operationsBlockData.resourceUse?.electricityUse) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "electricityUse",
        operationsBlockData.resourceUse,
        "electricityUse",
        userUOM.unitElectricityUse,
        defaultUnitsV2.unitElectricityUse
      );

    if (
      operationsBlockData.resourceUse?.gasUse ||
      Number(operationsBlockData.resourceUse?.gasUse) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "gasUse",
        operationsBlockData.resourceUse,
        "gasUse",
        userUOM.unitNaturalGasUse,
        defaultUnitsV2.unitNaturalGasUse
      );

    if (
      operationsBlockData.resourceUse?.waterUse ||
      Number(operationsBlockData.resourceUse?.waterUse) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "waterUse",
        operationsBlockData.resourceUse,
        "waterUse",
        userUOM.unitWaterUse,
        defaultUnitsV2.unitWaterUse
      );

    if (
      operationsBlockData.resourceUse?.selfGeneratedRenewables &&
      Array.isArray(operationsBlockData.resourceUse?.selfGeneratedRenewables)
    ) {
      operationsBlockData.resourceUse?.selfGeneratedRenewables.forEach(
        (item: ShrimpInterventionEnergyTypeInput) => {
          if (item && item.type) {
            const selfGeneratedRenewable = {
              id: uuidv4(),
              type: item.type,
              value: {},
            };

            setInterventionFloatValue(
              selfGeneratedRenewable,
              "value",
              item,
              "value",
              userUOM.unitElectricityUse,
              defaultUnitsV2.unitElectricityUse
            );

            resourceUse?.selfGeneratedRenewables?.push(selfGeneratedRenewable);
          }
        }
      );
    }

    if (
      operationsBlockData.resourceUse?.selfGeneratedRenewablesAdditions &&
      Array.isArray(operationsBlockData.resourceUse?.selfGeneratedRenewablesAdditions)
    ) {
      operationsBlockData.resourceUse?.selfGeneratedRenewablesAdditions.forEach(
        (item: ShrimpInterventionEnergyTypeInput) => {
          if (item && item.type) {
            const selfGeneratedRenewable = {
              id: uuidv4(),
              type: item.type,
              value: floatValueInDefaultUnit(
                item.value,
                userUOM.unitElectricityUse,
                defaultUnitsV2.unitElectricityUse
              ),
            };

            resourceUse?.selfGeneratedRenewablesAdditions?.push(selfGeneratedRenewable as ShrimpEnergyTypeInput);
          }
        }
      );
    }

    if (
      operationsBlockData.resourceUse?.energyTypes &&
      Array.isArray(operationsBlockData.resourceUse?.energyTypes)
    ) {
      operationsBlockData.resourceUse?.energyTypes.forEach(
        (item: ShrimpInterventionEnergyTypeInput) => {
          if (item && item.type) {
            let userUnit = "";
            let defaultUnitV2 = "";

            switch (item.type) {
              case EnergyCarrierType.Coal:
                userUnit = userUOM.unitHeatUse;
                defaultUnitV2 = defaultUnitsV2.unitHeatUse;
                break;
              case EnergyCarrierType.Diesel:
                userUnit = userUOM.unitDieselUse;
                defaultUnitV2 = defaultUnitsV2.unitDieselUse;
                break;
              case EnergyCarrierType.Gasoline:
                userUnit = userUOM.unitGasolineUse;
                defaultUnitV2 = defaultUnitsV2.unitGasolineUse;
                break;
              case EnergyCarrierType.HeavyFuelOil:
                userUnit = userUOM.unitHeavyFuelOilUse;
                defaultUnitV2 = defaultUnitsV2.unitHeavyFuelOilUse;
                break;
              case EnergyCarrierType.PropaneOrLpg:
                userUnit = userUOM.unitNaturalGasUse;
                defaultUnitV2 = defaultUnitsV2.unitNaturalGasUse;
                break;
              default:
                break;
            }

            const energyType = {
              id: uuidv4(),
              type: item.type,
              value: {},
            };

            setInterventionFloatValue(
              energyType,
              "value",
              item,
              "value",
              userUnit,
              defaultUnitV2
            );

            resourceUse?.energyTypes?.push(energyType);
          }
        }
      );
    }

    if (
      operationsBlockData.resourceUse?.energyTypesAdditions &&
      Array.isArray(operationsBlockData.resourceUse?.energyTypesAdditions)
    ) {
      operationsBlockData.resourceUse?.energyTypesAdditions.forEach(
        (item: ShrimpInterventionEnergyTypeInput) => {
          if (item && item.type) {
            let userUnit = "";
            let defaultUnitV2 = "";

            switch (item.type) {
              case EnergyCarrierType.Coal:
                userUnit = userUOM.unitHeatUse;
                defaultUnitV2 = defaultUnitsV2.unitHeatUse;
                break;
              case EnergyCarrierType.Diesel:
                userUnit = userUOM.unitDieselUse;
                defaultUnitV2 = defaultUnitsV2.unitDieselUse;
                break;
              case EnergyCarrierType.Gasoline:
                userUnit = userUOM.unitGasolineUse;
                defaultUnitV2 = defaultUnitsV2.unitGasolineUse;
                break;
              case EnergyCarrierType.HeavyFuelOil:
                userUnit = userUOM.unitHeavyFuelOilUse;
                defaultUnitV2 = defaultUnitsV2.unitHeavyFuelOilUse;
                break;
              case EnergyCarrierType.PropaneOrLpg:
                userUnit = userUOM.unitNaturalGasUse;
                defaultUnitV2 = defaultUnitsV2.unitNaturalGasUse;
                break;
              default:
                break;
            }

            const energyType = {
              id: uuidv4(),
              type: item.type,
              value: floatValueInDefaultUnit(
                item.value,
                userUnit,
                defaultUnitV2
              ),
            };

            resourceUse?.energyTypesAdditions?.push(energyType as ShrimpEnergyTypeInput);
          }
        }
      );
    }

    // pondWaterProperties
    if (
      operationsBlockData.resourceUse?.waterEnteringPondIn ||
      Number(operationsBlockData.resourceUse?.waterEnteringPondIn) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "waterEnteringPondIn",
        operationsBlockData.resourceUse,
        "waterEnteringPondIn",
        userUOM.unitWaterUse,
        defaultUnitsV2.unitWaterUse
      );

    if (
      operationsBlockData.resourceUse?.nitrogenConcentrationIn ||
      Number(operationsBlockData.resourceUse?.nitrogenConcentrationIn) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "nitrogenConcentrationIn",
        operationsBlockData.resourceUse,
        "nitrogenConcentrationIn"
      );

    if (
      operationsBlockData.resourceUse?.waterEnteringPondOut ||
      Number(operationsBlockData.resourceUse?.waterEnteringPondOut) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "waterEnteringPondOut",
        operationsBlockData.resourceUse,
        "waterEnteringPondOut",
        userUOM.unitWaterUse,
        defaultUnitsV2.unitWaterUse
      );

    if (
      operationsBlockData.resourceUse?.nitrogenConcentrationOut ||
      Number(operationsBlockData.resourceUse?.nitrogenConcentrationOut) === 0
    )
      setInterventionFloatValue(
        resourceUse,
        "nitrogenConcentrationOut",
        operationsBlockData.resourceUse,
        "nitrogenConcentrationOut"
      );

    if (operationsBlockData.resourceUse?.watersalinity)
      resourceUse!.watersalinity =
        operationsBlockData.resourceUse.watersalinity;

    // ice
    setInterventionFloatValue(
      operations,
      "iceAmount",
      operationsBlockData,
      "iceAmount",
      userUOM.unitBarnOutputMass,
      defaultUnitsV2.unitBarnOutputMass
    );

    // fertilizers
    operationsBlockData.fertilizerTypes?.forEach(
      (item: FertilizerTypeInput) => {
        if (item && item.type) {
          const fertilizerType = {
            id: uuidv4(),
            type: item.type,
            amount: {},
          };

          setInterventionFloatValue(
            fertilizerType,
            "amount",
            item,
            "amount",
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          );

          fertilizerTypes?.push(fertilizerType);
        }
      }
    );

    // fertilizers - additions
    if (
      operationsBlockData.fertilizerTypeAdditions &&
      Array.isArray(operationsBlockData.fertilizerTypeAdditions)
    ) {
      operationsBlockData.fertilizerTypeAdditions?.forEach((item) => {
        if (item.type) {
          const newFertilizer = {
            id: uuidv4(),
            quantity: floatValueInDefaultUnit(
              item.amount,
              userUOM.unitBarnOutputMass,
              defaultUnitsV2.unitBarnOutputMass
            ),
            type: item.type,
          };

          operations.fertilizerTypeAdditions?.push(newFertilizer);
        }
      });
    }

    // materialsUse
    operationsBlockData.materialTypes?.forEach(
      (item: ShrimpMaterialTypeInput) => {
        if (item && item.type) {
          const materialType = {
            id: uuidv4(),
            type: item.type,
            amount: {},
            lifetimeOfMaterial: {},
            transportMode: { value: item.transportMode, change_type: "set" },
            distanceTraveled: {},
          };

          setInterventionFloatValue(
            materialType,
            "amount",
            item,
            "amount",
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          );

          setInterventionFloatValue(
            materialType,
            "lifetimeOfMaterial",
            item,
            "lifetimeOfMaterial"
          );

          setInterventionFloatValue(
            materialType,
            "distanceTraveled",
            item,
            "distanceTraveled",
            getUserTransportUnit(item.transportMode),
            getDefaultTransportUnit(item.transportMode)
          );

          materialTypes?.push(
            materialType as ShrimpGrowingStageMaterialTypeInput
          );
        }
      }
    );

    // materialsUse - additions
    if (
      operationsBlockData.materialTypeAdditions &&
      Array.isArray(operationsBlockData.materialTypeAdditions)
    ) {
      operationsBlockData.materialTypeAdditions?.forEach((item) => {
        if (item && item.type) {
          const newMaterial = {
            id: uuidv4(),
            type: item.type,
            amount: floatValueInDefaultUnit(
              item.amount,
              userUOM.unitBarnOutputMass,
              defaultUnitsV2.unitBarnOutputMass
            ),
            lifeTime: floatValueInDefaultUnit(
              item.distanceTraveled
            ),
            transport: [{
              mode: item.transportMode, 
              distance: floatValueInDefaultUnit(
                item.distanceTraveled,
                getUserTransportUnit(item.transportMode),
                getDefaultTransportUnit(item.transportMode)
              ),
            }],
          };

          operations.materialTypeAdditions?.push(
            newMaterial as StageMaterialDBModel
          );
        }
      });
    }

    // chemicalsUse
    operationsBlockData.chemicalTypes?.forEach(
      (item: ShrimpChemicalTypeInput) => {
        if (item && item.type) {
          const chemicalType = {
            id: uuidv4(),
            type: item.type,
            amount: {},
          };

          setInterventionFloatValue(
            chemicalType,
            "amount",
            item,
            "amount",
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          );

          chemicalTypes?.push(chemicalType);
        }
      }
    );

    // chemicalsUse - additions
    if (
      operationsBlockData.chemicalTypeAdditions &&
      Array.isArray(operationsBlockData.chemicalTypeAdditions)
    ) {
      operationsBlockData.chemicalTypeAdditions?.forEach((item) => {
        if (item && item.type) {
          const newChemical = {
            id: uuidv4(),
            amount: floatValueInDefaultUnit(
              item.amount,
              userUOM.unitBarnOutputMass,
              defaultUnitsV2.unitBarnOutputMass
            ),
            type: item.type,
          };

          operations.chemicalTypeAdditions?.push(
            newChemical as ChemicalDBModel
          );
        }
      });
    }
  }
};

const mapOutputForOneStage = (
  outputBlockData: ShrimpInterventionOutputs,
  stageGeneralData: ShrimpStageDBModel
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = stageGeneralData;
  const { output } = stageData;

  if (outputBlockData) {
    outputBlockData.outputs?.forEach((item: ShrimpInterventionOutput) => {
      const outputData = {
        id: uuidv4(),
        calculateLca: item.calculateLca,
        name: item.name,
        price: {},
        soldExternally: item.soldExternally,
        totalWeight: {},
        proteinContent: {},
      };

      item.price || Number(item.price) === 0
        ? setInterventionFloatValue(outputData, "price", item, "price")
        : undefined;

      setInterventionFloatValue(
        outputData,
        "totalWeight",
        item,
        "totalWeight",
        userUOM.unitBarnOutputMass,
        defaultUnitsV2.unitBarnOutputMass
      );

      setInterventionFloatValue(
        outputData,
        "proteinContent",
        item,
        "proteinContent"
      );

      output?.outputs?.push(outputData as ShrimpOutput);
    });

    if (
      outputBlockData.outputAdditions &&
      Array.isArray(outputBlockData.outputAdditions)
    ) {
      outputBlockData.outputAdditions?.forEach((item) => {
        const newOutput = {
          id: uuidv4(),
          calculateLca: item.calculateLca,
          weight: floatValueInDefaultUnit(
            item.totalWeight,
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          ),
          name: item.name,
          price: Number(item.price),
          soldExternally: item.soldExternally === "Yes",
          proteinContent: Number(item.proteinContent),
        };

        output?.outputAdditions?.push(newOutput as AnimalsOutputDBModel);
      });
    }
  }
};

const mapStageInterventionDataOutSustell = (stageBlockData: ShrimpStage) => {
  const { stageData } = stageBlockData;
  const { input, feed, operations, output } = stageData;

  const oneStageData = {
    id: stageBlockData?.id || uuidv4(),
    name: stageBlockData?.name,
    type: stageBlockData?.type,
    stageData: { ...initStageData() },
  };

  if (stageData && input) {
    setInterventionFloatValue(
      oneStageData.stageData.input,
      "stageDuration",
      stageBlockData.stageData.input,
      "stageDuration"
    );

    mapInputForOneStage(input, oneStageData);
  }

  if (stageData && feed && oneStageData?.stageData?.feedingProgram)
    mapRationForOneStage(feed, oneStageData.stageData.feedingProgram);

  if (stageData && operations) {
    mapOperationsForOneStage(operations, oneStageData);
  }

  if (stageData && output?.outputs) {
    mapOutputForOneStage(output, oneStageData);
  }

  return oneStageData;
};

const mapShrimpInterventionDataOutSustell = (formData: ShrimpIntervention) => {
  let payloadObj = createCorePayload(formData);
  payloadObj = mapResourceUseSustell(formData, payloadObj);

  if (formData.stages && Array.isArray(formData.stages)) {
    return formData.stages.reduce(
      (payload, stage) => {
        if (!stage?.stageData) return payloadObj;

        const mappedStage = mapStageInterventionDataOutSustell(stage);

        return {
          ...payload,
          stages: [
            ...payload.stages,
            {
              ...mappedStage,
              stageData: JSON.stringify(mappedStage.stageData),
            },
          ],
        };
      },
      { ...payloadObj }
    );
  }

  return payloadObj;
};

export default mapShrimpInterventionDataOutSustell;
