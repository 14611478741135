import { IntlShape } from "react-intl";
import * as Yup from 'yup';
import { interventionValidationResourseUsagePartV2 } from './interventionValidationSchemaGeneralPart';
import { feedAdditionalItemsInterventionTest, interventionFieldWithMin } from "./interventionValidationObjectBuilderFunctions";
import { feedItemsTest, feedItemsTestWithOrigin, numericOptionalWithGreaterThanMin, numericRequiredWithGreaterThanMin } from "../../Baseline/validationSchema/validationObjectBuilderFunctions";

const stageDataPartShrimp = (intl: IntlShape) =>
  Yup.object({
    stages: Yup.array().of(
      Yup.object({
        stageData: Yup.object({
          input: Yup.object({
            internalSourcesAdditions: Yup.array().of(
              Yup.object({
                comingFromFarm: Yup.string().required(
                  intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                ),
                comingFromStage: Yup.string().required(
                  intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                ),
                outputName: Yup.string().required(
                  intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                ),
                totalWeight: numericRequiredWithGreaterThanMin(intl, 0).required(),
              })
            ),
            externalSourcesAdditions: Yup.array().of(
              Yup.object({
                totalWeight: numericRequiredWithGreaterThanMin(intl, 0).required(),
              })
            ),
          }).default({}),

          feed: Yup.object({
            compoundFeedsAdditions: feedItemsTest(intl),
            singleIngredientsAdditions: feedItemsTestWithOrigin(intl, 0),
          }).default({}),

          operations: Yup.object({
            fertilizerTypeAdditions: Yup.array().of(
              Yup.object({
                type: Yup.string(),
                amount: Yup.number().when('type', {
                  is: (val: string) => val,
                  then: () => numericRequiredWithGreaterThanMin(intl, 0),
                  otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                }),
              })
            ),
            materialTypeAdditions: Yup.array().of(
              Yup.object({
                type: Yup.string(),
                amount: Yup.number().when('type', {
                  is: (val: string) => val,
                  then: () => numericRequiredWithGreaterThanMin(intl, 0),
                  otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                }),
                lifetimeOfMaterial: Yup.number().when('type', {
                  is: (val: string) => val,
                  then: () => numericRequiredWithGreaterThanMin(intl, 0),
                  otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                }),
              })
            ),
            chemicalTypeAdditions: Yup.array().of(
              Yup.object({
                type: Yup.string(),
                amount: Yup.number().when('type', {
                  is: (val: string) => val,
                  then: () => numericRequiredWithGreaterThanMin(intl, 0),
                  otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                }),
              })
            ),
            resourceUse: Yup.object({
              selfGeneratedRenewablesAdditions: Yup.array().of(
                Yup.object({
                  type: Yup.string().required(),
                  value: Yup.number().when('type', {
                    is: (val: string) => val,
                    then: () => numericRequiredWithGreaterThanMin(intl, 0),
                    otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                  }),
                })
              ),
              energyTypesAdditions: Yup.array().of(
                Yup.object({
                  type: Yup.string().required(),
                  value: Yup.number().when('type', {
                    is: (val: string) => val,
                    then: () => numericRequiredWithGreaterThanMin(intl, 0),
                    otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                  }),
                })
              )
            })
          }).default({}),

          output: Yup.object({
            outputAdditions: Yup.array().of(
              Yup.object({
                name: Yup.string()
                  .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
                  .min(
                    3,
                    intl.formatMessage({ id: 'VALIDATION.FIELD.MIN_LENGTH' }, { count: 3 })
                  ),
                totalWeight: Yup.number().when('name', {
                  is: (val: string) => val,
                  then: () => numericRequiredWithGreaterThanMin(intl, 0),
                  otherwise: () => numericOptionalWithGreaterThanMin(intl, 0),
                }),
                soldExternally: Yup.string().required(
                  intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
                ),
              }).default({})
            )
          })
        }).default({})
      })
    ).required().min(1, 'At least one stage data is needed.'),
  });

const assembleValidationSchemaSustellShrimp = (
  baseSchema: any,
  confObj: { intl: IntlShape; userUOM?: any; }
) => {
  const infoObject = baseSchema;
  const combinedSchema = Yup.object({ info: infoObject })
    .concat(Yup.object({ resourceUse: interventionValidationResourseUsagePartV2(confObj) }))
    .concat(stageDataPartShrimp(confObj.intl));

  return combinedSchema;
};

export default assembleValidationSchemaSustellShrimp;
