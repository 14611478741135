import { FC, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import {
    defaultUnitsV2,
    unitLong,
} from '../../../../utils/unit-utils';
import lookupValues from '../../../../helpers/lookupValues';
import {
    AnimalType,
    CompoundFeedDatabase,
} from '../../../../../../graphql/types';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { isMarineFish, isShrimp } from '../../../../helpers/animals';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import ShrimpResourceAdditionalItem from './ShrimpResourceAdditionalItem';
import { ShrimpIntervention, ShrimpResourceUse } from '../../../../models/Intervention/ShrimpIntervention';
import { ShrimpBaseline } from '../../../../models/Baseline/ShrimpBaseline';
import SustellResourceAdditionalItem from '../../../../../modules/Helpers/SustellResourceAdditionalItem';

interface ResourcesProps {
    formType: FormType;
    inputType: string;
    databaseFoundation?: CompoundFeedDatabase;
    animalType?: AnimalType;
    required?: boolean;
    onChange?: () => void;
    editEnabled?: boolean;
    baseline: ShrimpBaseline;
    stageIndex: number;
}

const ShrimpResourcesDialog: FC<ResourcesProps> = ({
    formType = FormType.View,
    inputType,
    stageIndex = 0,
    databaseFoundation,
    animalType,
    required = false,
    editEnabled = false,
    baseline,
    onChange,
}) => {
    const intl = useIntl();
    const classes = processAndStageStylesV2() as CSSClassesList;
    const userProfile = UserProfilePrefs.getInstance();
    const userUOM = userProfile.getUserUnitPrefs();

    const resourcesData = baseline?.stages[stageIndex].stageData.operations?.resourceUse as ShrimpResourceUse;
    let unavailableOptions: Array<string> = [];

    const { control, getValues } = useFormContext();

    const {
        fields: selfGeneratedRenewables,
        append: appendSelfGeneratedRenewables,
        remove: removeSelfGeneratedRenewablesItem,
    } = useFieldArray({
        name: `${inputType}.selfGeneratedRenewablesAdditions`,
        control,
        keyName: 'selfGeneratedRenewablesAdditionsId',
    });

    const {
        fields: energyTypes,
        append: appendAnotherEnergyType,
        remove: removeAnotherEnergyTypeItem,
    } = useFieldArray({
        name: `${inputType}.energyTypesAdditions`,
        control,
        keyName: 'energyTypesAdditionsId',
    });

    const removeSelfGeneratedRenewables = (index: number) => {
        removeSelfGeneratedRenewablesItem(index);
        // To enforce draft saving set touched to true
        control.updateFormState({
            touched: { [`${inputType}.selfGeneratedRenewablesAdditions`]: true },
        });
    };

    const removeAnotherEnergyType = (index: number) => {
        removeAnotherEnergyTypeItem(index);
        control.updateFormState({
            touched: { [`${inputType}.energyTypesAdditions`]: true },
        });
    };

    // CF form fields have _ naming
    const getFormFieldName = (fieldName: string): string => {
        return `${inputType}.${fieldName}`;
    };

    return (
        <>
            <DsmGrid
                className={classes.dsmGridOneColumn}
                style={{ rowGap: 0, width: '100%' }}
            >
                <DsmGrid className={classes.dsmGridTwoColumn}                >
                    {!editEnabled || !resourcesData?.electricityUse ? (
                        <ReactHookDsmInput
                            name={getFormFieldName('originalElectricityUse')}
                            label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.ELECTRICITY', })}
                            disabled={editEnabled === false}
                            tooltip={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.ELECTRICITY.SHRIMP.TOOLTIP' })}
                            type="number"
                            adornment={
                                userUOM?.unitElectricityUse
                                    ? unitLong(userUOM.unitElectricityUse)
                                    : defaultUnitsV2.unitElectricityUse
                            }
                            required={required}
                            changeHandler={() => {
                                if (onChange) onChange();
                            }}
                            defaultValue={resourcesData?.electricityUse as string}
                        />) : (
                        <RowTextFieldWithMetrics
                            name={getFormFieldName('electricityUse')}
                            label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.ELECTRICITY', })}
                            type="number"
                            tooltip={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.ELECTRICITY.SHRIMP.TOOLTIP' })}
                            metricUnit=''
                            baseline={resourcesData?.electricityUse}
                            disabled={formType === FormType.View || !editEnabled}
                            displayTypeSelect={editEnabled}
                        />
                    )}
                </DsmGrid>

                {resourcesData.selfGeneratedRenewables?.map((item, index) => (
                    <div>
                        <DsmGrid
                            style={{
                                grid: `auto/1fr ${'0.88fr'} 32px`,
                                gap: 'var(--dsm-spacing-px-4)',
                                alignItems: 'start',
                                alignContent: ' space-between',
                                padding: '0px',
                                marginTop: `var(--dsm-spacing-px-2)`,
                            }}
                        >
                            <ShrimpResourceAdditionalItem
                                labelInput={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_USE',
                                })}
                                labelSelect={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE',
                                })}
                                placeholderSelect=""
                                tooltipType={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE.TOOLTIP',
                                })}
                                tooltipUse={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_USE.TOOLTIP',
                                })}
                                index={index}
                                formType={formType}
                                defaultValueAmount={Number(item?.value)}
                                defaultValueType={item?.type}
                                required={required}
                                fieldItemPrefix={`${inputType}.selfGeneratedRenewables`}
                                editEnabled={editEnabled}
                                isRenewables
                                onChange={onChange}
                                unavailableOptions={resourcesData.selfGeneratedRenewables?.map(
                                    (item) => item.type
                                ) || []}
                            />
                        </DsmGrid>
                    </div>
                ))}

                {selfGeneratedRenewables?.map((item, index) => (
                    <div key={item.selfGeneratedRenewablesAdditionsId}>
                        <DsmGrid
                            style={{
                                grid: `auto/1fr ${'0.88fr'} 32px`,
                                gap: 'var(--dsm-spacing-px-4)',
                                alignItems: 'start',
                                alignContent: ' space-between',
                                padding: '0px',
                                marginTop: `var(--dsm-spacing-px-2)`,
                            }}
                        >
                            <SustellResourceAdditionalItem
                                labelInput={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_USE',
                                })}
                                labelSelect={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE',
                                })}
                                placeholderSelect=""
                                tooltipType={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE.TOOLTIP',
                                })}
                                tooltipUse={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_USE.TOOLTIP',
                                })}
                                index={index}
                                formType={formType}
                                defaultValueAmount={Number(item?.value)}
                                deafultValueType={item?.type}
                                required={required}
                                fieldItemPrefix={`${inputType}.selfGeneratedRenewablesAdditions`}
                                isRenewables
                                isIntervention
                                onChange={onChange}
                                editEnabled={editEnabled}
                                unavailableOptions={resourcesData.selfGeneratedRenewables?.map(
                                    (item) => item.type
                                ) || []}
                            />

                            {formType !== FormType.View && editEnabled &&
                                removeSelfGeneratedRenewables &&
                                selfGeneratedRenewables.length > 0 && (
                                    <DsmButton
                                        variant="text"
                                        destructive
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            padding: '0px',
                                            marginTop: '24px',
                                        }}
                                        onClick={() => {
                                            removeSelfGeneratedRenewables(index);
                                            unavailableOptions.splice(item.type);
                                            if (onChange) onChange();
                                        }}
                                    >
                                        <DsmIcon
                                            name="general/trash-01"
                                            style={{
                                                position: 'absolute',
                                                right: '0',
                                            }}
                                        />
                                    </DsmButton>
                                )
                            }
                        </DsmGrid>
                    </div>
                ))}

                {editEnabled ? (
                    <DsmButton
                        variant="text"
                        onClick={() => {
                            appendSelfGeneratedRenewables({ type: '', value: '' });
                            if (onChange) onChange();
                        }}
                        disabled={
                            formType === FormType.View ||
                            selfGeneratedRenewables.length >=
                            (lookupValues.selfGeneratedRenewablesList.length - (resourcesData.selfGeneratedRenewables?.length ?? 0))
                        }
                        style={{ paddingTop: 0 }}
                    >
                        <DsmIcon slot="before" name="general/plus-circle" />
                        {intl.formatMessage({
                            id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_BUTTON',
                        })}
                    </DsmButton>
                ) : (<></>)}
            </DsmGrid>


            <br />

            <DsmGrid className={classes.dsmGridTwoColumn}                >
                {!editEnabled || !resourcesData?.gasUse ? (
                    <ReactHookDsmInput
                        name={getFormFieldName('originalGasUse')}
                        label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.GAS', })}
                        tooltip={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.GAS.SHRIMP.TOOLTIP' })}
                        type="number"
                        adornment={
                            userUOM?.unitNaturalGasUse
                                ? unitLong(userUOM.unitNaturalGasUse)
                                : defaultUnitsV2.unitNaturalGasUse
                        }
                        disabled={formType === FormType.View || !editEnabled}
                        required={required}
                        changeHandler={() => {
                            if (onChange) onChange();
                        }}
                        defaultValue={resourcesData?.gasUse as string}
                    />) : (
                    <>
                        <RowTextFieldWithMetrics
                            name={getFormFieldName('gasUse')}
                            label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.GAS', })}
                            type="number"
                            tooltip={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.GAS.SHRIMP.TOOLTIP' })}
                            metricUnit=''
                            baseline={resourcesData?.gasUse}
                            disabled={formType === FormType.View || !editEnabled}
                            displayTypeSelect={editEnabled}
                        />
                    </>)}
            </DsmGrid>

            {resourcesData.energyTypes?.map((item, index) => (
                <div>
                    <DsmGrid
                        style={{
                            grid: `auto/1fr ${'0.88fr'} 32px`,
                            gap: 'var(--dsm-spacing-px-4)',
                            alignItems: 'start',
                            alignContent: ' space-between',
                            padding: '0px',
                            marginTop: `var(--dsm-spacing-px-2)`,
                        }}
                    >
                        <ShrimpResourceAdditionalItem
                            labelInput={intl.formatMessage({
                                id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE',
                            })}
                            labelSelect={intl.formatMessage({
                                id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE',
                            })}
                            placeholderSelect=""
                            tooltipType={intl.formatMessage({
                                id: isShrimp(animalType)
                                    ? 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE.SHRIMP.TOOLTIP'
                                    : 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE.TOOLTIP',
                            })}
                            tooltipUse={intl.formatMessage({
                                id: isShrimp(animalType)
                                    ? 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE.SHRIMP.TOOLTIP'
                                    : 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE.TOOLTIP',
                            })}
                            index={index}
                            formType={formType}
                            defaultValueAmount={Number(item?.value)}
                            defaultValueType={item?.type}
                            required={required}
                            fieldItemPrefix={`${inputType}.energyTypes`}
                            editEnabled={editEnabled}
                            onChange={onChange}
                            unavailableOptions={resourcesData.energyTypes?.map(
                                (item) => item.type
                            ) || []}
                            />
                    </DsmGrid>
                </div>
            ))}

            {energyTypes?.map((item, index) => (
                <div key={item.energyTypesAdditionsId}>
                    <DsmGrid
                        style={{
                            grid: `auto/1fr ${'0.88fr'} 32px`,
                            gap: 'var(--dsm-spacing-px-4)',
                            alignItems: 'start',
                            alignContent: ' space-between',
                            padding: '0px',
                            marginTop: `var(--dsm-spacing-px-2)`,
                        }}
                    >
                        <SustellResourceAdditionalItem
                            labelInput={intl.formatMessage({
                                id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE',
                            })}
                            labelSelect={intl.formatMessage({
                                id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE',
                            })}
                            placeholderSelect=""
                            tooltipType={intl.formatMessage({
                                id: isShrimp(animalType)
                                    ? 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE.SHRIMP.TOOLTIP'
                                    : 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE.TOOLTIP',
                            })}
                            tooltipUse={intl.formatMessage({
                                id: isShrimp(animalType)
                                    ? 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE.SHRIMP.TOOLTIP'
                                    : 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE.TOOLTIP',
                            })}
                            index={index}
                            formType={formType}
                            defaultValueAmount={Number(item?.value)}
                            deafultValueType={item?.type}
                            required={required}
                            fieldItemPrefix={`${inputType}.energyTypesAdditions`}
                            onChange={onChange}
                            isIntervention
                            editEnabled={editEnabled}
                            unavailableOptions={resourcesData.energyTypes?.map(
                                (item) => item.type
                            ) || []
                            }
                        />

                        {formType !== FormType.View && editEnabled &&
                            removeAnotherEnergyType &&
                            energyTypes.length > 0 && (
                                <DsmButton
                                    variant="text"
                                    destructive
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        padding: '0px',
                                        marginTop: '24px',
                                    }}
                                    onClick={() => {
                                        removeAnotherEnergyType(index);
                                        unavailableOptions.splice(item.type);
                                        if (onChange) onChange();
                                    }}
                                >
                                    <DsmIcon
                                        name="general/trash-01"
                                        style={{
                                            position: 'absolute',
                                            right: '0',
                                        }}
                                    />
                                </DsmButton>
                            )}
                    </DsmGrid>
                </div>
            ))}

            {editEnabled ? (
                <DsmButton
                    variant="text"
                    onClick={() => {
                        appendAnotherEnergyType({ type: '', value: '' });
                        if (onChange) onChange();
                    }}

                    disabled={
                        formType === FormType.View ||
                        energyTypes.length >= (lookupValues.energyTypesList.length - (resourcesData.energyTypes?.length ?? 0))
                    }
                    style={{ paddingTop: 0 }}
                >
                    <DsmIcon slot="before" name="general/plus-circle" />
                    {intl.formatMessage({
                        id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY.TYPE_BUTTON',
                    })}
                </DsmButton>
            ) : (<></>)}
        </>
    );
};

export default ShrimpResourcesDialog;
