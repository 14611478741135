import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { Maybe } from "graphql/jsutils/Maybe";

import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { FormType } from "../../common";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { processAndStageStylesV2 } from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import ReactHookDsmRadioGroup, {
  RadioGroupOption,
} from "../../../../../modules/Helpers/ReactHookDsmRadioGroup";
import RowTextFieldWithMetrics from "../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2";
import { ShrimpBaseline } from "../../../../models/Baseline/ShrimpBaseline";

interface ShrimpOutputFormProps {
  stageIndex?: number;
  itemIndex?: number;
  formType: FormType;
  editEnabled?: boolean;
  removeHandler?: Maybe<Function>;
  baseline: ShrimpBaseline;
}

const ShrimpOutputForm: FC<ShrimpOutputFormProps> = ({
  stageIndex = 0,
  itemIndex = 0,
  formType,
  editEnabled = false,
  removeHandler,
  baseline,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;

  const formContext = useFormContext();

  const fieldItemPrefix = `stages.${stageIndex}.stageData.output.outputs.${itemIndex}`;
  const outputData =
    baseline.stages[stageIndex].stageData.output.outputs[itemIndex];

  const inputTotalPondArea = baseline.stages[stageIndex].stageData.input
    .totalPondArea as number;

  const totalWeight = outputData.totalWeight as number;

  const weightPerHectare = useMemo(
    () =>
      inputTotalPondArea ? (totalWeight / inputTotalPondArea).toFixed(2) : 0,
    [totalWeight]
  );

  const soldExternallyOptions: RadioGroupOption[] = [
    {
      value: "Yes",
      label: intl.formatMessage({
        id: "GENERAL.YES",
      }),
    },
    {
      value: "No",
      label: intl.formatMessage({
        id: "GENERAL.NO",
      }),
    },
  ];

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.calculateLca`}
        value={outputData?.calculateLca}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.name`}
        value={outputData?.name}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.soldExternally`}
        value={outputData?.soldExternally}
      />
      <DsmGrid className={classes.dsmGridOneColumnWithBorder}>
        {removeHandler && editEnabled && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            onClick={() => removeHandler(itemIndex)}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}

        <DsmGrid className={editEnabled ? classes.dsmGridTwoColumnsInterventionComponent : classes.dsmGridTwoColumn}
          style={{ paddingBottom: editEnabled ? 'var(--dsm-spacing-px-2)' : '' }}
        >
          {!editEnabled && !formContext.getValues(`${fieldItemPrefix}.totalWeight`) ? (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalPrice`}
              label={`${intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SHRIMP.PRICE',
              })}`}
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SHRIMP.PRICE.TOOLTIP',
              })}
              adornment={intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SHRIMP.PRICE.PLACEHOLDER',
              })}
              type="number"
              disabled
              required
              defaultValue={outputData?.price as string}
            />) : (
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.price`}
              label={intl.formatMessage({ id: 'BASELINE.FORM.OUTPUT.SHRIMP.PRICE' })}
              type="number"
              tooltip={intl.formatMessage({
                id: 'BASELINE.FORM.OUTPUT.SHRIMP.PRICE.TOOLTIP',
              })}
              metricUnit=''
              baseline={outputData?.price}
              disabled={formType === FormType.View || !editEnabled}
              displayTypeSelect={editEnabled}
            />
          )}

          <ReactHookDsmRadioGroup
            name={`${fieldItemPrefix}.originalSoldExternally`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.OUTPUT.SHRIMP.SOLD_EXTERNALLY',
            })}`}
            options={soldExternallyOptions}
            disabled
            required
            defaultValue={outputData?.soldExternally as string}
          />
        </DsmGrid>

        <DsmGrid className={editEnabled ? classes.dsmGridOneColumn : classes.dsmGridTwoColumn} style={{ gap: editEnabled ? '10px' : '' }}>
          {!editEnabled &&
            !formContext.getValues(`${fieldItemPrefix}.totalWeight`) ? (
              <ReactHookDsmInput
                name={`${fieldItemPrefix}.originalTotalWeight`}
                label={`${intl.formatMessage({
                  id: "BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT",
                })}`}
                tooltip={intl.formatMessage({
                  id: "BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT.TOOLTIP",
                })}
                adornment={intl.formatMessage({
                  id: "BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT.PLACEHOLDER",
                })}
                type="number"
                disabled
                required
                helperText={intl.formatMessage(
                  { id: "BASELINE.FORM.OUTPUT.SHRIMP.AVERAGE_WEIGHT_HECTARE" },
                  {
                    weight: weightPerHectare,
                  }
                )}
                defaultValue={outputData?.totalWeight as string}
              />
          ) : (
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.totalWeight`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT",
              })}
              type="number"
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT.TOOLTIP",
              })}
              metricUnit=""
              baseline={outputData?.totalWeight}
              disabled={formType === FormType.View || !editEnabled}
              displayTypeSelect={editEnabled}
            />
          )}

          {!editEnabled &&
            !formContext.getValues(`${fieldItemPrefix}.proteinContent`) ? (
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.originalProteinContent`}
              label={`${intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT",
              })}`}
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT.TOOLTIP",
              })}
              adornment={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT.PLACEHOLDER",
              })}
              type="number"
              disabled
              defaultValue={outputData?.proteinContent as string}
            />
          ) : (
            <RowTextFieldWithMetrics
              name={`${fieldItemPrefix}.proteinContent`}
              label={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT",
              })}
              type="number"
              tooltip={intl.formatMessage({
                id: "BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT.TOOLTIP",
              })}
              metricUnit=""
              baseline={outputData?.proteinContent}
              disabled={formType === FormType.View || !editEnabled}
              displayTypeSelect={editEnabled}
            />
          )}
        </DsmGrid>
      </DsmGrid>
    </>
  );
};

export default ShrimpOutputForm;
